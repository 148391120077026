import axios, { AxiosResponse } from "axios";

const base = process.env.REACT_APP_API_URL;

//Eventually will be for when we want to change something, but not retrieve something i.e. POST
export const mutator = async (url: string, method: string, body: object) => {
  const axiosInstance = axios.create({
    baseURL: base,
    timeout: 5000,
  });

  const res = await axiosInstance({
    url: url,
    method,
    headers: { "Content-Type": "application/json" },
    data: body,
  }).then((res: AxiosResponse) => res.data);

  console.log(res);
};

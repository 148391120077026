import {Sleep} from "../types/Sleep";
import {fetcher} from "./Fetcher";
import {mutator} from "./Mutator";

export function fetchSleepList(uid: string) {
    return fetcher<Sleep[]>(`/sleep/${uid}`, "GET");
}

export function createSleepEntry(body?: any | null) {
    return mutator("/sleep", "POST", body);
}

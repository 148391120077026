import {fetcher} from "./Fetcher";
import {mutator} from "./Mutator";
import {Water} from "../types/Water";

export function fetchWaterList(uid: string) {
    return fetcher<Water[]>(`/water/${uid}`, "GET");
}

export function createWaterEntry(body?: any | null) {
    return mutator("/water", "POST", body);
}

import React, {useState} from "react";
import {TestButton, TrackerContent, TrackerMaster} from "./PersonalityTracker.styles";
import {useMutation, useQuery} from "@tanstack/react-query";
import {Chronotype} from "../../types/Chronotype";
import {createChronotype, fetchChronotype, updateChronotype} from "../../services/ChronotypeService";
import {queryClient} from "../../services/QueryClient";
import {getUserID} from "../../services/firebase";

type questionaire = {
    question: string;
    name: string;
    value: number;
    answers: string[];
}

const initial: questionaire[] = [
    {
        question: "Considering your own \"feeling best\" rhythm, at what time would you get up if you were entirely free to plan your day?",
        name: "quest1",
        value: 0,
        answers: [
            "5:00 - 6:30 a.m",
            "6:30 - 7:45 a.m",
            "7:45 - 9:45 a.m",
            "9:45 - 11:00 a.m",
            "11:00 a.m. - 12:00 (noon)"
        ]
    },
    {
        question: "Considering your only \"feeling best\" rhythm, at what time would you go to bed if you were entirely free to plan your evening?",
        name: "quest2",
        value: 0,
        answers: [
            "8:00 - 9:00 p.m",
            "9:00 - 10:15 p.m",
            "10:15 p.m. - 12:30 a.m",
            "12:30 a.m. - 1:45 a.m",
            "1:45 a.m. - 3:00 a.m"
        ]
    },
    {
        question: "Assuming normal circumstance, how easy do you find getting up in the morning?",
        name: "quest3",
        value: 0,
        answers: [
            "Not at all easy",
            "Slightly easy",
            "Fairly easy",
            "Very easy"
        ]
    },
    {
        question: "How alert do you feel during the first half hour after having awakened in the morning?",
        name: "quest4",
        value: 0,
        answers: [
            "Not at all alert",
            "Slightly alert",
            "Fairly alert",
            "Very alert"
        ]
    },
    {
        question: "During the first half hour after having awakened in the morning, how tired do you feel?",
        name: "quest5",
        value: 0,
        answers: [
            "Very tired",
            "Fairly tired",
            "Fairly refreshed",
            "Very refreshed"
        ]
    },
    {
        question: "You have decided to engage in some physical exercise. A friend suggests that you do this one hour " +
            "twice a week and the best time for him is 7:00-8:00 a.m. Bearing in mind nothing else but your own " +
            "\"feeling best\" rhythm, how do you think you would perform?",
        name: "quest6",
        value: 0,
        answers: [
            "Would be in good form",
            "Would be in a reasonable form",
            "Would find it difficult",
            "Would find it very difficult"
        ]
    },
    {
        question: "At what time in the evening do you feel tired and, as a result, in need of sleep?",
        name: "quest7",
        value: 0,
        answers: [
            "8:00 - 9:00 p.m",
            "9:00 - 10:15 p.m",
            "10:15 p.m. - 12:30 a.m",
            "12:30 a.m. - 1:45 a.m",
            "1:45 a.m. - 3:00 a.m"
        ]
    },
    {
        question: "You wish to be at your peak performance for a test which you know is going to be mentally exhasting" +
            " and lasting for two hours. You are entirely free to plan your day, and considering only you own " +
            "\"feeling best\" rhythm, which ONE of the four testing times would you choose?",
        name: "quest8",
        value: 0,
        answers: [
            "8:00 - 10:00 a.m",
            "11:00 a.m. - 1:00 p.m",
            "3:00 - 5:00 p.m",
            "7:00 - 9:00 p.m"
        ]
    },
    {
        question: "One hears about \"morning\" and \"evening\" types of people. Which ONE of these types do you consider yourself to be?",
        name: "quest9",
        value: 0,
        answers: [
            "Definitively a morning type",
            "More a morning than an evening type",
            "More an evening than a morning type",
            "Definitively an evening type"
        ]
    },
    {
        question: "When would you prefer to rise (provided you have a full day's work - 8 hours) if you were totally free to arrange your time?",
        name: "quest10",
        value: 0,
        answers: [
            "Before 6:30 a.m",
            "6:30 - 7:30 a.m",
            "7:30 - 8:30 a.m",
            "8:30 a.m. or later"
        ]
    },
    {
        question: "If you always had to rise at 6:00 a.m., what do you think it would be like?",
        name: "quest11",
        value: 0,
        answers: [
            "Very difficult and unpleasant",
            "Rather difficult and unpleasant",
            "A little unpleasant but no great problem",
            "Easy and not unpleasant"
        ]
    },
    {
        question: "How long a time does it usually take before you \"recover your senses\" in the morning after rising from a night's sleep?",
        name: "quest12",
        value: 0,
        answers: [
            "0 - 10 minutes",
            "11 - 20 minutes",
            "21 - 40 minutes",
            "more than 40 minutes"
        ]
    },
    {
        question: "Please indicate to what extent you are a morning or evening active individual?",
        name: "quest13",
        value: 0,
        answers: [
            "Pronounced morning active (morning alert and evening tired)",
            "To some extent, morning active",
            "To some extent, evening active",
            "Pronounced evening active (morning tired and evening alert)"
        ]
    }
]

export default function ChronotypeQuiz() {
    const [questionList, setList] = useState<questionaire[]>(initial);

    const getChrono = useQuery<Chronotype, Error>({
        queryKey: ["chronotype"],
        queryFn: () => fetchChronotype(getUserID()),
        refetchInterval: false
    });

    const postChrono = useMutation({
        mutationKey: ["chrono"],
        mutationFn: (body: any) => createChronotype(body),
        onSuccess: () => {
            console.log("Successfully created chronotype!");
            queryClient.invalidateQueries({queryKey: ["chronotype"]});
            window.location.replace(window.location.origin + "/chronoResults");
        }
    });

    const editChrono = useMutation({
        mutationKey: ["chrono"],
        mutationFn: (body: any) => updateChronotype(body),
        onSuccess: () => {
            console.log("Successfully updated chronotype!");
            queryClient.invalidateQueries({queryKey: ["chronotype"]});
            window.location.replace(window.location.origin + "/chronoResults");
        }
    });

    const submit = () => {
        const answerList: number[] = [];

        for (let i = 0; i < questionList.length; i++) {
            answerList.push(questionList[i].value);
        }

        const body = {
            userID: getUserID(),
            questions: answerList,
            date: new Date()
        }

        if (getChrono.data) {
            editChrono.mutate(body);
        } else {
            postChrono.mutate(body);
        }
    }

    const answerRenderer = (answers: string[], name: string, qIndex: number) => answers.map((answer, index) => (
        <p style={{textAlign: "left"}}>
            <input
                type={"radio"}
                name={name}
                onClick={() => {
                    let list = questionList;
                    questionList[qIndex].value = index + 1;
                    setList(list);
                }}
            />{answer}
        </p>
    ));

    const questionRenderer = () => questionList.map((quest, index) => (
        <>
            <h5 style={{textAlign: "left"}}>{index + 1}. {quest.question}</h5>
            {answerRenderer(quest.answers, quest.name, index)}
        </>
    ));

    return (
        <TrackerMaster>
            <div id={"firebaseui-auth-container"} style={{display: "none"}}/>
            <TrackerContent>
                <h1>Chronotype Survey</h1>
                <p>Find where you fall on the scale from morning to evening type by answering the questions below</p>
                {questionRenderer()}
                <TestButton onClick={() => submit()}>Submit</TestButton>
            </TrackerContent>
        </TrackerMaster>
    );
}

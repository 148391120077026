import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { CircularSlider } from 'react-web-circular-slider';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {createSleepEntry, fetchSleepList} from "../services/Sleep";
import {queryClient} from "../services/QueryClient";
import {useMutation, useQuery} from "@tanstack/react-query";
import {User} from "../types/User";
import {fetchUser} from "../services/User";
import {getUserID} from "../services/firebase";

Modal.setAppElement('#root');

interface TimeObject {
    h: number;
    m: number;
}

function padTime(time: number | string): string {
    if (`${time}`.length < 2) {
        return `0${time}`;
    }
    return `${time}`;
}

// AM/PM format
const formatTime = (time: TimeObject): string => {
    let { h, m } = time;

    if (h >= 12) {
        if (h !== 12) {
            h = h - 12;
        }
        if (h == 0) {
            h = 12;
        }
        return `${padTime(h)}:${padTime(m)}pm`;
    }
    if (h == 0) {
        h = 12;
    }
    return `${padTime(h)}:${padTime(m)}am`;
};

// 24hr format
const formatTimeMilitary = (time: TimeObject): string => {
    let { h, m } = time;

    return `${padTime(h)}:${padTime(m)}`;
};

function areTimesOnSameDay(startTime: TimeObject, endTime: TimeObject): boolean {
    const startMinutes = startTime.h * 60 + startTime.m;
    const endMinutes = endTime.h * 60 + endTime.m;
    const timeDifference = endMinutes - startMinutes;
    // If the time difference is negative, it means the endTime is on the next day
    return timeDifference >= 0;
}


const SleepPopUp: React.FC<{ isOpen: boolean; onClose: () => void; }> = ({ isOpen, onClose }) => {
    const [bedTime, setBedTime] = useState<TimeObject>({h: 18, m: 0});
    const [wakeTime, setWakeTime] = useState<TimeObject>({h: 6, m: 0});
    const [durationHr, setDurationHr] = useState(12);
    const [durationMin, setDurationMin] = useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date(new Date().getTime() + 24 * 60 * 60 * 1000));
    const [isMilitaryTime, setIsMilitaryTime] = useState(false);

    // used to fix wheel bugs
    const [timeUpdated, setTimeUpdated] = useState(true);
    const [isStartChanged, setIsStartChanged] = useState(false);

    const createSleep = useMutation({
        mutationKey: ["sleep"],
        mutationFn: (body: any) => createSleepEntry(body),
        onSuccess: () => {
            console.log("Successfully created sleep entry!");
            return queryClient.invalidateQueries({queryKey: ["sleep"]});
        }
    });

    const getUser = useQuery<User, Error>({
        queryKey: ["user"],
        queryFn: () => fetchUser("1234567890")
    });

    /* createSleepBody = {
    *   userID: string;
    *   start: Date;
    *   end: Date;
    * }
    */


    useEffect(() => {
            if(timeUpdated) {
                setDurationHr(12);
                setDurationMin(0);
            }
    }, [bedTime, wakeTime]);

    useEffect(() => {
        if(isStartChanged) {
            adjustEndDate(startDate);
        } //handles bug when startTime hasn't been moved yet
         else if(wakeTime.h > 18) {
            setEndDate(startDate);
        } else {
            setEndDate(new Date(startDate.getTime() + 24 * 60 * 60 * 1000));
        }
    }, [bedTime]);

    useEffect(() => {
        if(isStartChanged) {
            adjustStartDate(endDate);
        } //handles bug when startTime hasn't been moved yet
        else if(wakeTime.h > 18) {
            setEndDate(startDate);
        } else {
            setEndDate(new Date(startDate.getTime() + 24 * 60 * 60 * 1000));
        }
    }, [wakeTime]);

    const onCloseModal = () => {
        // reset booleans
        setTimeUpdated(true);
        setIsStartChanged(false);
        onClose();
    };

    function calculateTimeDifference(startTime: TimeObject, endTime: TimeObject, greaterDistance: boolean) {
        let duration = 0;

        const absoluteDifference = Math.abs((endTime.h * 60 + endTime.m) - (startTime.h * 60 + startTime.m));

        if (greaterDistance) {
            duration = Math.max(absoluteDifference, 144000 - absoluteDifference);
        } else {
            duration = Math.min(absoluteDifference, 144000 - absoluteDifference);
        }

        let hours = Math.floor(duration / 6000);
        const minutes = duration % 60;

        return { hours, minutes };
    }


    const toggleTimeFormat = () => {
        setIsMilitaryTime((prev) => !prev);
        setWakeTime((prev) => (prev ? convertTo24HourFormat(prev) : prev!));
        setBedTime((prev) => (prev ? convertTo24HourFormat(prev) : prev!));
    };

    const formatTimeFunction = isMilitaryTime ? formatTimeMilitary : formatTime;

    const adjustStartDate = (date: Date): void => {
        let adjustedDate: Date;
        if (areTimesOnSameDay(bedTime, wakeTime)) {
            adjustedDate = date;
        } else {
            // Sets the start date to the previous day
            adjustedDate = new Date(date.getTime() - 24 * 60 * 60 * 1000);
        }
        setStartDate(adjustedDate);
    };

    const adjustEndDate = (date: Date): void => {
        let adjustedDate: Date;
        if (areTimesOnSameDay(bedTime, wakeTime)) {
            adjustedDate = date;
        } else {
            // Sets the end date to the next day
            adjustedDate = new Date(date.getTime() + 24 * 60 * 60 * 1000);
        }
        setEndDate(adjustedDate);
    };


    const convertTo24HourFormat = (timeObject: TimeObject): TimeObject => {
        let { h, m } = timeObject;
        if (h < 12 && timeObject.h >= 12) {
            h += 12;
        }
        return { h, m };
    };


    const onStartChange = ({ startTime }: { startTime: TimeObject }) => {
        setBedTime(startTime);
        adjustEndDate(startDate);
        if(startTime.h !== 18) {
            setTimeUpdated(false);
            setIsStartChanged(true);
        }
    };

    const onEndChange = ({ endTime }: { endTime: TimeObject }) => {
        setWakeTime(endTime);
        if(isStartChanged) {
            adjustStartDate(endDate);
        }
        if(endTime.h !== 6) {
            setTimeUpdated(false);
        }
    };

    const handleStartDateChange = (date: Date) => {
        setStartDate(date);
        adjustEndDate(date);
    };

    const handleEndDateChange = (date: Date) => {
        setEndDate(date);
        adjustStartDate(date);
    };

    //updates when the wheel is moved
    const onUpdate = ({ startTime, endTime, durationMinutes }: { startTime: TimeObject; endTime: TimeObject; durationMinutes: number }) => {
        let timeDifference: { hours: number; minutes: number; };
        let greaterDistance = false;
        if(isStartChanged) {
            setBedTime(startTime);
            setWakeTime(endTime);
            greaterDistance = durationMinutes > 720;
            timeDifference = calculateTimeDifference(startTime, endTime, greaterDistance);
            setDurationHr(timeDifference.hours);
            setDurationMin(timeDifference.minutes);
        } else { //fixes weird bug where wakeTime is inaccurate until the bedTime is changed
            setBedTime({h: 18, m: 0});
            // @ts-ignore
            if(endTime.h == '0-1') {
                endTime.h = 23;
                // @ts-ignore
            } else if(endTime.h == '0-2') {
                endTime.h = 22;
                // @ts-ignore
            } else if(endTime.h == '0-3') {
                endTime.h = 21;
                // @ts-ignore
            } else if(endTime.h == '0-4') {
                endTime.h = 20;
                // @ts-ignore
            } else if(endTime.h == '0-5') {
                endTime.h = 19;
                // @ts-ignore
            } else if(endTime.h == '0-6') {
                endTime.h = 18;
            }
            setWakeTime(endTime);
            setDurationHr(Math.floor(durationMinutes / 60));
            setDurationMin(durationMinutes % 60);
        }
    };

    const handleEnterSleep = async () => {
        const body = {
            userID: getUserID(),
            start_sleep: new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), bedTime.h, bedTime.m),
            end_sleep: new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), wakeTime.h, wakeTime.m),
        };
        try {
            await createSleep.mutateAsync(body);
            onCloseModal();
        } catch (error) {
            console.error("Error creating sleep entry:", error);
            // Handle error
        }
    };
    // Add media query for mobile screens
    const isMobile = window.matchMedia("(max-width: 768px)").matches;

    if (isMobile) {
        return <>
            <style>{`
                @keyframes popOut {
                  0% {
                    transform: translate(-50%, -50%) scale(0.5);
                  }
                  100% {
                    transform: translate(-50%, -50%) scale(1);
                  }
                }
            `}</style>

            <Modal
                isOpen={isOpen}
                onRequestClose={onClose}
                contentLabel="Sleep Popup"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: '1000',
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        height: '60%',
                        borderRadius: '2.5vw',
                        // minWidth: '700px',
                        // minHeight: '450px',
                        animation: `popOut 0.5s ease-out forwards`,
                        backgroundColor: getUser.data?.data.darkMode ? `#212121` : 'white',
                    },
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: '2.55vw',
                        left: '2.5vw',
                        cursor: 'pointer',
                        fontSize: '5vw',
                        fontWeight: 'lighter',
                        color: 'gray',
                    }}
                    onClick={toggleTimeFormat}
                >
                    {!isMilitaryTime ? 'AM/PM' : '24hr'}
                </div>
                <div style={{
                    position: 'absolute',
                    top: '5vw',
                    left: '2.5vw',
                }}>
                </div>
                <div
                    style={{
                        position: 'absolute',
                        top: '3vw',
                        right: '5vw',
                        cursor: 'pointer',
                        fontSize: '10vw',
                        fontWeight: 'lighter',
                        color: 'gray',
                    }}
                    onClick={onCloseModal}
                >
                    x
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', margin: '0px'}}>
                    <div style={{ color: 'darkgrey', display: 'flex', justifyContent: 'space-evenly', marginTop: '10vw', marginBottom: '1vw', marginLeft: '12vw' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ color: '#7300ff', fontSize: '5vw' }}>Bedtime</div>
                            <div style={{ fontSize: '7vw', color: 'black' }}>{formatTimeFunction(bedTime)}</div>
                            <DatePicker selected={startDate} onChange={handleStartDateChange}  />
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column'}}>
                            <div style={{ color: '#0095ff', fontSize: '5vw' }}>Wake</div>
                            <div style={{ fontSize: '7vw', color: 'black' }}>{formatTimeFunction(wakeTime)}</div>
                            <DatePicker selected={endDate} onChange={handleEndDateChange}  />
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', color: 'black', marginBottom: '4vw'}}>
                        <div style={{ position: 'absolute', justifySelf: 'center', top: '50%' }}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ fontSize: '5vw', color: 'black' }}>{durationHr}</div>
                                <div style={{ alignSelf: 'end', paddingBottom: '0.1vw', fontSize: '4vw' }}>HR</div>
                                <div style={{ margin: '1.5vw' }}></div>
                                <div style={{ fontSize: '5vw', color: 'black' }}>{durationMin}</div>
                                <div style={{ alignSelf: 'end', paddingBottom: '0.1vw', fontSize: '4vw' }}>MIN</div>
                            </div>
                        </div>
                        <CircularSlider
                            onStartUpdate={onStartChange}
                            onEndUpdate={onEndChange}
                            onUpdate={onUpdate}
                            segments={100}
                            strokeWidth={0.027*window.innerHeight}
                            radius={0.145*window.innerHeight}
                            gradientColorFrom="#7300ff"
                            gradientColorTo="#0095ff"
                            showClockFace
                            clockFaceColor="#9d9d9d"
                            bgCircleColor="#171717"
                        />
                        <button
                            onClick={handleEnterSleep}
                            style={{
                                backgroundColor: '#457AB2',
                                color: 'white',
                                border: 'none',
                                borderRadius: '5vw',
                                padding: '3vw 5vw',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                                position: 'absolute',
                                bottom: '4vw',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                cursor: 'pointer',
                                marginTop: '0px',
                                fontSize: '4vw',
                            }}
                        >
                            Enter Sleep
                        </button>
                    </div>
                </div>
            </Modal>
        </>;
    }

    return (
        <>
            <style>{`
                @keyframes popOut {
                  0% {
                    transform: translate(-50%, -50%) scale(0.5);
                  }
                  100% {
                    transform: translate(-50%, -50%) scale(1);
                  }
                }
            `}</style>

            <Modal
                isOpen={isOpen}
                onRequestClose={onClose}
                contentLabel="Sleep Popup"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: '1000',
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '50%',
                        height: '50%',
                        borderRadius: '2.5vw',
                        // minWidth: '700px',
                        // minHeight: '450px',
                        animation: `popOut 0.5s ease-out forwards`,
                        backgroundColor: getUser.data?.data.darkMode ? "#212121" : "white"
                    },
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: '2.5vh',
                        left: '2.5vh',
                        cursor: 'pointer',
                        fontSize: '3vh',
                        fontWeight: 'lighter',
                        color: 'gray',
                    }}
                    onClick={toggleTimeFormat}
                >
                    {!isMilitaryTime ? 'AM/PM' : '24hr'}
                </div>
                <div style={{
                    position: 'absolute',
                    top: '5vh',
                    left: '2.5vh',
                }}>
                </div>
                <div
                    style={{
                        position: 'absolute',
                        top: '1.5vh',
                        right: '2.5vh',
                        cursor: 'pointer',
                        fontSize: '5vh',
                        fontWeight: 'lighter',
                        color: 'gray',
                    }}
                    onClick={onCloseModal}
                >
                    x
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', margin: '0px'}}>
                    <div style={{ color: 'darkgrey', display: 'flex', justifyContent: 'space-evenly', marginTop: '0.5vh', marginBottom: '0.5vh', marginLeft: '6vw' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ color: '#7300ff', fontSize: '2.5vh' }}>Bedtime</div>
                            <div style={{ fontSize: '3.5vh', color: 'black' }}>{formatTimeFunction(bedTime)}</div>
                            <DatePicker selected={startDate} onChange={handleStartDateChange} />
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column'}}>
                            <div style={{ color: '#0095ff', fontSize: '2.5vh' }}>Wake</div>
                            <div style={{ fontSize: '3.5vh', color: 'black' }}>{formatTimeFunction(wakeTime)}</div>
                            <DatePicker selected={endDate} onChange={handleEndDateChange}  />
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', color: 'black', marginBottom: '2vh'}}>
                        <div style={{ position: 'absolute', justifySelf: 'center', top: '50%' }}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ fontSize: '3vh', color: 'black' }}>{durationHr}</div>
                                <div style={{ alignSelf: 'end', paddingBottom: '0.4vh', fontSize: '2vh' }}>HR</div>
                                <div style={{ margin: '0.75vh' }}></div>
                                <div style={{ fontSize: '3vh', color: 'black' }}>{durationMin}</div>
                                <div style={{ alignSelf: 'end', paddingBottom: '0.4vh', fontSize: '2vh' }}>MIN</div>
                            </div>
                        </div>
                        <CircularSlider
                            onStartUpdate={onStartChange}
                            onEndUpdate={onEndChange}
                            onUpdate={onUpdate}
                            segments={100}
                            strokeWidth={0.027*window.innerHeight}
                            radius={0.145*window.innerHeight}
                            gradientColorFrom="#7300ff"
                            gradientColorTo="#0095ff"
                            showClockFace
                            clockFaceColor="#9d9d9d"
                            bgCircleColor="#171717"
                        />
                        <button
                            onClick={handleEnterSleep}
                            style={{
                                backgroundColor: '#457AB2',
                                color: 'white',
                                border: 'none',
                                borderRadius: '2.5vh',
                                padding: '1.5vh 2.5vh',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                                position: 'absolute',
                                bottom: '2vh',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                cursor: 'pointer',
                                marginTop: '0px',
                                fontSize: '2vh',
                            }}
                        >
                            Enter Sleep
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default SleepPopUp;

import React, { useState } from 'react';
import Modal from 'react-modal';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {createMoodEntry, fetchMoodList} from "../services/Mood";
import {queryClient} from "../services/QueryClient";
import {useMutation, useQuery} from "@tanstack/react-query";
import {User} from "../types/User";
import {fetchUser} from "../services/User";
import {getUserID} from "../services/firebase";

Modal.setAppElement('#root');

const MoodPopUp = ({ isOpen, onClose }) => {
    const [startDate, setStartDate] = useState(new Date());
    const [happyChecked, setHappyChecked] = useState(false);
    const [satisfiedChecked, setSatisfiedChecked] = useState(false);
    const [relaxedChecked, setRelaxedChecked] = useState(false);
    const [sadChecked, setSadChecked] = useState(false);
    const [angryChecked, setAngryChecked] = useState(false);

    const enterMood = async ()  => {
        if(happyChecked) {
            const body = {
                userID: getUserID(), // Replace with actual user ID
                date: new Date(),
                mood: 1,
            };
            try {
                await createMood.mutateAsync(body);
            } catch (error) {
                console.error("Error creating mood entry:", error);
                // Handle error
            }
        }
        if(satisfiedChecked) {
            const body = {
                userID: getUserID(), // Replace with actual user ID
                date: new Date(),
                mood: 2,
            };
            try {
                await createMood.mutateAsync(body);
            } catch (error) {
                console.error("Error creating mood entry:", error);
                // Handle error
            }
        }
        if(relaxedChecked) {
            const body = {
                userID: getUserID(), // Replace with actual user ID
                date: new Date(),
                mood: 3,
            };
            try {
                await createMood.mutateAsync(body);
            } catch (error) {
                console.error("Error creating mood entry:", error);
                // Handle error
            }
        }
        if(sadChecked) {
            const body = {
                userID: getUserID(), // Replace with actual user ID
                date: new Date(),
                mood: 4,
            };
            try {
                await createMood.mutateAsync(body);
            } catch (error) {
                console.error("Error creating mood entry:", error);
                // Handle error
            }
        }
        if(angryChecked) {
            const body = {
                userID: getUserID(), // Replace with actual user ID
                date: new Date(),
                mood: 5,
            };
            try {
                await createMood.mutateAsync(body);
            } catch (error) {
                console.error("Error creating mood entry:", error);
                // Handle error
            }
        }
        onClose();
    }

    const createMood = useMutation({
        mutationKey: ["mood"],
        mutationFn: (body: any) => createMoodEntry(body),
        onSuccess: () => {
            console.log("Successfully created mood entry!");
            return queryClient.invalidateQueries({queryKey: ["mood"]});
        }
    });


    const getUser = useQuery<User, Error>({
        queryKey: ["user"],
        queryFn: () => fetchUser("1234567890")
    });

    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    let w='0', h='0';
    if(isMobile) {
        w = '80%';
        h = '40%';
    } else{
        w = '50%';
        h = '50%';
    }

    return (
        <>
            <style>
                {`
                @keyframes popOut {
                  0% {
                    // opacity: 0;
                    transform: translate(-50%, -50%) scale(0.5);
                  }
                  100% {
                    // opacity: 1;
                    transform: translate(-50%, -50%) scale(1);
                  }
                }
            `}
            </style>
            <Modal
                isOpen={isOpen}
                onRequestClose={onClose}
                contentLabel="Mood Popup"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: '1000',
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: w,
                        height: h,
                        borderRadius: '2.5vw',
                        // minWidth: '700px',
                        // minHeight: '450px',
                        animation: `popOut 0.5s ease-out forwards`,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        position: 'relative',
                        backgroundColor: getUser.data?.data.darkMode ? "#212121" : "white",
                        color: getUser.data?.data.darkMode ? "white" : "black"
                    },
                }}
            >
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                }}>
                <div style={{
                    position: 'absolute',
                    top: '13vh',
                    left: '2vw',
                }}>
                    Click the date to change it!
                    <DatePicker
                        selected={startDate}
                        onChange={(date: Date) => setStartDate(date)}
                    />
                </div>
                <div
                    style={{
                        position: 'absolute',
                        top: '1.5vh',
                        right: '2.5vh',
                        cursor: 'pointer',
                        fontSize: '5vh',
                        fontWeight: 'lighter',
                        color: 'gray',
                    }}
                    onClick={onClose}
                >
                    x
                </div>
                <h2 style={{textAlign: 'center'}}>How are you feeling today?</h2>
                <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', width: '95%', fontSize: '5vh', marginTop: '3vh' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <p>😆</p>
                        <FormControlLabel
                            value="top"
                            control={<Checkbox checked={happyChecked} onChange={(e) => setHappyChecked(e.target.checked)} />}
                            label="Happy"
                            labelPlacement="top"
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
                        <p>🙂</p>
                        <FormControlLabel
                            value="top"
                            control={<Checkbox checked={satisfiedChecked} onChange={(e) => setSatisfiedChecked(e.target.checked)} />}
                            label="Satisfied"
                            labelPlacement="top"
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
                        <p>😌</p>
                        <FormControlLabel
                            value="top"
                            control={<Checkbox checked={relaxedChecked} onChange={(e) => setRelaxedChecked(e.target.checked)} />}
                            label="Relaxed"
                            labelPlacement="top"
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
                        <p>😢</p>
                        <FormControlLabel
                            value="top"
                            control={<Checkbox checked={sadChecked} onChange={(e) => setSadChecked(e.target.checked)} />}
                            label="Sad"
                            labelPlacement="top"
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
                        <p>😡</p>
                        <FormControlLabel
                            value="top"
                            control={<Checkbox checked={angryChecked} onChange={(e) => setAngryChecked(e.target.checked)} />}
                            label="Angry"
                            labelPlacement="top"
                        />
                    </div>
                </div>
                <div>
                    <button onClick={enterMood} style={{
                        backgroundColor: '#457AB2',
                        color: 'white',
                        border: 'none',
                        borderRadius: '2.5vh',
                        padding: '1.5vh 2.5vh',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                        position: 'absolute',
                        bottom: '2vh',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        cursor: 'pointer',
                        marginTop: '0px',
                        fontSize: '2vh',
                    }}>
                        Enter Mood
                    </button>
                </div>
                </div>
            </Modal>
        </>
    );
};

export default MoodPopUp;

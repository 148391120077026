import React from 'react';
import "../../styles/awesome-bootstrap-checkbox-master/awesome-bootstrap-checkbox.css";
import "../../styles/ItsBedtime.css";

export default function MindfulLeadership() {
    return (
        <div>
            <br />
            <br/>
            <iframe width="550"
                    style={{ maxWidth: '100%', aspectRatio: '16 / 9' }}
                    src="https://www.youtube.com/embed/v0CNZLIkIqw"
                    frameBorder="no"
                    allow="autoplay"/>
            <p>
            <b>Video Description:</b>
            This video from the discusses how mindfulness can be applied to build stronger leadership skills.
            </p>
            <p>
            <a href="https://www.youtube.com/watch?v=v0CNZLIkIqw&feature=emb_title">Source</a>
            </p>
            <br />
            <hr className="hr-settings"/>
            <br />
            <p>Spotify Mindful Leadership Playlist</p>
            <br/>
            <a href="https://open.spotify.com/episode/5ntvD94xb2UqWa3soLlVWI">
            <img src="https://media.gettyimages.com/id/1204023261/vector/think-different-and-freedom-concept.jpg?s=612x612&w=0&k=20&c=0NNO6gQFe-beESVgPVtJaOUPKyOXsX8hhI4GcZ6BGts="
            alt="Spotify Mindful Leadership" width="550" height="400" style={{ maxWidth: '100%', height: 'auto' }}/></a>
        </div>
    );
}

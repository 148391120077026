import React, {useEffect, useState} from 'react'
import "./DarkModeSwitch.css"
import {useMutation, useQuery} from "@tanstack/react-query";
import {User} from "../../types/User";
import {fetchUser, updateUser} from "../../services/User";
import {queryClient} from "../../services/QueryClient";
import {getUserID} from "../../services/firebase";

export default function DarkModeSwitch() {
    const getUser = useQuery<User, Error>({
        queryKey: ["user"],
        queryFn: () => fetchUser(getUserID())
    });

    const editUser = useMutation({
        mutationKey: ["user"],
        mutationFn: (body: any) => updateUser(body),
        onSuccess: () => {
            console.log("Successfully updated user!");
            return queryClient.invalidateQueries({queryKey: ["user"]});
        }
    });

    const [isToggled, setIsToggled] = useState(getUser.data ? getUser.data.data.darkMode : false);

    useEffect(() => {
        getUser.refetch();
    }, [isToggled]);

    const onToggle = () => {
        try {
            editUser.mutate({
                id: getUser.data?.id,
                darkMode: `${!isToggled}`
            });
            setIsToggled(!isToggled);
        } catch (error) {
            console.error("Error updating user:", error);
        }
    };

    return (
        <label className={"switch"}>
            <input type={"checkbox"} checked={isToggled} onChange={() => onToggle()}/>
            <span className={"slider"}></span>
        </label>
    )
}
import styled from "styled-components";
import {device} from "../../util/device";

export const LandingTop = styled.div`
  background: linear-gradient(180deg, #1f9ce490 0%, #625ad890 40%, #7339ab90 100%),
    url("/images/dawn.jpg") no-repeat center center fixed;
  height: 100vh;
  padding-top: 210px;

  @media only screen and ${device.mobileM} {
    display: flow;
    text-align: center;
  }

  @media only screen and ${device.tablet} {
    display: flex;
    justify-content: space-evenly;
  }
`;

export const TitleBlock = styled.div`
  h1 {
    color: white;
    font-weight: normal;
    font-size: 40px;
  }

  h2 {
    color: white;
    font-weight: normal;
  }
`;

export const LearnButton = styled.button`
  background: #337ab7;
  border: 2px solid #ffffff;
  box-shadow: 5px 7px #00000030;
  border-radius: 100px;
  color: #ffffff !important;
  font-size: 1.2em;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 13px 22px;
  margin: 20px !important;
  transition: all 0.4s ease-in-out;
  cursor: pointer;

  &:hover {
    background: #ffffff;
    border: 2px solid #337ab7;
    color: #337ab7 !important;
  }
`;

export const PageButtons = styled.div`
    background-color: #141629;
    display: flex;
    justify-content: space-evenly;

    @media only screen and ${device.mobileM} {
        flex-direction: column;
    }

    @media only screen and ${device.tablet} {
        flex-direction: row;
    }
`;

export const ButtonArea = styled.div`
    text-align: center;
    padding-top: 75px;
    padding-bottom: 50px;
    
    a {
        scroll-behavior: smooth;
        text-decoration: none;
    }
  
    h2 {
        color: white;
        font-weight: normal;
    }

    @media only screen and ${device.mobileM} {
        width: 100%;
    }

    @media only screen and ${device.tablet} {
        width: 25%;
    }
`;

export const ShowButton = styled.button<ImageProps>`
  scroll-behavior: smooth;
  background: url(${(props) => props.imageurl}) no-repeat center;
  background-size: 75%;
  height: 250px;
  width: 250px;
  margin: 20px;
  border-radius: 50%;
  cursor: pointer;
`;

export const InfoSection = styled.div<InfoProps>`
  background: radial-gradient(${(props) => props.leftright}, ${(props) => props.gradcolor} 30%, transparent),
          url(${(props) => props.imageurl}) no-repeat center fixed;
  background-size: cover;
  display: flex;
  flex-direction: column;
  padding-bottom: 75px;
  height: 100vh;
  
  h1 {
    margin: 30px;
    padding-top: 50px;
    font-size: 40pt;
    font-weight: bold;
    text-shadow: 6px 5px #00000080;
    color: white;
  }
  
  h3 {
    color: white;
    padding-left: 3%;
    text-shadow: 2px 2px black;
    

      @media only screen and ${device.mobileM} {
          width: 90%;
      }

      @media only screen and ${device.tablet} {
          width: 30%;
      }
  }
`;

export const InfoImage =styled.div<ImageProps>`
  background: url(${(props) => props.imageurl}) no-repeat center;
  background-size: contain;
  width: 250px;
  height: 250px;
  margin: 20px;
`;

interface InfoProps {
    imageurl: string;
    gradcolor: string;
    leftright: string;
}

interface ImageProps {
    imageurl: string;
}

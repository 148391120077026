import {TestButton, TrackerContent, TrackerMaster} from "./PersonalityTracker.styles";
import React from "react";
import {useQuery} from "@tanstack/react-query";
import {Chronotype} from "../../types/Chronotype";
import {fetchChronotype} from "../../services/ChronotypeService";
import {getUserID} from "../../services/firebase";

export default function ChronotypeResults() {
    const getChrono = useQuery<Chronotype, Error>({
        queryKey: ["chronotype"],
        queryFn: () => fetchChronotype(getUserID()),
        refetchInterval: false
    });

    const calculateScore = (q: number[]) => {
        let score = 0;

        //Add up scores, some need to be reversed i.e. value 5 = 1
        score += (q[0] % 5) + 1;
        score += (q[1] % 5) + 1;
        score += q[2] + q[3] + q[4];
        score += (q[5] % 4) + 1;
        score += (q[6] % 5) + 1;
        score += (q[7] % 4) + 1;
        score += (q[8] % 4) + 1;
        score += (q[9] % 4) + 1;
        score += q[10];
        score += (q[11] % 4) + 1;
        score += (q[12] % 4) + 1;

        return score;
    }

    const dateView = (date: Date) => {
        const value = new Date(date);

        return (
            value.toDateString()
        );
    }

    const getMessage = (score: number) => {
        if(score < 22) {
            return "You are definitely an evening type (or a night owl as some would say)!";
        } else if(score > 44) {
            return "You are definitely a morning type (or a lark as some would say)!";
        } else if(score < 33) {
            return "You tend towards an evening type but fall somewhere in between a morning or evening type.";
        } else {
            return "You tend towards a morning type but fall somewhere in between a morning or evening type.";
        }
    }

    return (
        <TrackerMaster>
            <div id={"firebaseui-auth-container"} style={{display: "none"}}/>
            <TrackerContent>
                <h1>Chronotype Results</h1>
                {getChrono.data ?
                    (
                        <>
                            <h3>Quiz was last taken on {dateView(getChrono.data.data.date)}</h3>
                            <h5 style={{fontWeight: "bolder"}}>Chronotype
                                Score: {calculateScore(getChrono.data.data.questions)}</h5>
                            <h5>{getMessage(calculateScore(getChrono.data.data.questions))}</h5>
                            <a href={"/chronoQuiz"}><TestButton>Retake Chronotype Quiz</TestButton></a>
                        </>
                    ) : (
                        <>
                        <h3>It looks like you haven't taken the test yet. Take the quiz below!</h3>
                            <a href={"/chronoQuiz"}><TestButton>Take Chronotype Quiz</TestButton></a>
                        </>
                    )
                }
            </TrackerContent>
        </TrackerMaster>
);
}
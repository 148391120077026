import React, {useState} from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import { useQuery } from "@tanstack/react-query";
import {Mood} from "../types/Mood";
import {fetchMoodList} from "../services/Mood";
import {ButtonLogger, DaysButton, MoodDiv} from "../pages/Dashboard/Dashboard.styles";
import MoodPopUp from "./moodModal";
import {getUserID} from "../services/firebase";

const isMobile = window.matchMedia("(max-width: 799px)").matches;
let labelSize = '0.8vw';
if(isMobile) {
    labelSize = '3.5vw'
}

const COLORS = ['#88b8ef', '#398dd2', '#7050da', '#a269f1', `#d989e0`];

let renderLabel = function(entry) {
    return entry.emoji + " " + entry.name;
}

export default function MoodGraph() {
    const [isMoodModalOpen, setIsMoodModalOpen] = useState(false);
    const [selectedDuration, setSelectedDuration] = useState(7);
    const openMoodModal = () => {
        setIsMoodModalOpen(true);
    };

    const closeMoodModal = () => {
        setIsMoodModalOpen(false);
    };

    let happy = 0, satisfied = 0, relaxed = 0, sad = 0, angry = 0;

    const getMood = useQuery<Mood[], Error>({
        queryKey: ["mood"],
        queryFn: () => fetchMoodList(getUserID())
    });

    if (getMood.data) {
        const currDate = new Date();
        const compare = new Date(currDate.getFullYear(), currDate.getMonth(), currDate.getDate() - selectedDuration);
        const selectedData = getMood.data.slice(0, selectedDuration).filter((a) => {
            return new Date(a.data.date) > compare;
        });
        selectedData.forEach(mood => {
            if (mood.data.mood === 1) {
                happy++;
            } else if (mood.data.mood === 2) {
                satisfied++;
            } else if (mood.data.mood === 3) {
                relaxed++;
            } else if (mood.data.mood === 4){
                sad++;
            } else if (mood.data.mood === 5) {
                angry++;
            } else {
                //oopsies
            }
        });
    }

    const data = [
        { name: 'Happy', emoji: '😆', value: happy},
        { name: 'Satisfied', emoji: '🙂', value: satisfied},
        { name: 'Relaxed', emoji: '😌', value: relaxed},
        { name: 'Sad', emoji: '😢', value: sad},
        { name: 'Angry', emoji: '😡', value: angry}
    ];

    return (
        <MoodDiv>
            <div style={{
                display: "flex",
                justifyContent: "end"
            }}>
                <DaysButton onClick={() => setSelectedDuration(7)} className={selectedDuration === 7 ? "active" : ""}>7 Days</DaysButton>
                <DaysButton onClick={() => setSelectedDuration(30)} className={selectedDuration === 30 ? "active" : ""}>30 Days</DaysButton>
            </div>
            <PieChart width={460} height={300} style={{width: "100%", height: "90%", maxWidth:"400px", marginLeft:"auto", marginRight:"auto"}}>
                <Pie
                    data={data}
                    cx="50%"
                    cy="50%"
                    labelLine={true}
                    label={renderLabel}
                    outerRadius={"80%"}
                    fill="#8884d8"
                    dataKey="value"
                    fontSize={labelSize}
                    style={{zIndex: '201'}}
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
            </PieChart>
            <MoodPopUp isOpen={isMoodModalOpen} onClose={closeMoodModal} />
            <ButtonLogger onClick={openMoodModal}>Log Mood</ButtonLogger>
        </MoodDiv>
    );
}

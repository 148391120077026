import React, { useState } from "react";
import MindfulYoga from "../../components/MindfulModules/MindfulYoga";
import MindfulEating from "../../components/MindfulModules/MindfulEating";
import FiveMinMind from "../../components/MindfulModules/FiveMinMind";
import TenMinuteMind from "../../components/MindfulModules/TenMinuteMind";
import MindfulnessOverview from "../../components/MindfulModules/MindfulnessOverview";
import MindfulLeadership from "../../components/MindfulModules/MindfulLeadership";
import { MindButton, MindfulHeader, MindfulMiddle, MindfulnessSearchBar, MindfulOverlord } from "./Mindfulness.styles";
import {useQuery} from "@tanstack/react-query";
import {User} from "../../types/User";
import {fetchUser} from "../../services/User";

export default function Mindfulness() {
    const [currState, setState] = useState("Overview");

    const getUser = useQuery<User, Error>({
        queryKey: ["user"],
        queryFn: () => fetchUser("1234567890")
    });

    const renderer = () => {
        if (currState === "Overview") {
            return <MindfulnessOverview />
        } else if (currState === "Yoga") {
            return <MindfulYoga />
        } else if (currState === "Eating") {
            return <MindfulEating />
        } else if (currState === "Leadership") {
            return <MindfulLeadership />
        } else if (currState === "5 Min Meditation") {
            return <FiveMinMind />
        } else {
            return <TenMinuteMind />
        }
    }
        const handleChange = (e) => {
            const selectedValue = e.target.value;
            setState(selectedValue);
        };


    return (
        <MindfulOverlord>
            <MindfulMiddle className={`${getUser.data?.data.darkMode ? 'dark-mode' : ''}`}>
            <div id={"firebaseui-auth-container"} style={{ display: "none" }} />
            <MindfulHeader>Mindfulness and Meditation</MindfulHeader>
            <MindfulnessSearchBar className={`${getUser.data?.data.darkMode ? 'dark-mode' : ''}`}>
                <select className={"menu"} value={currState} onChange={handleChange}>
                    <option value="Overview">Overview</option>
                    <option value="Yoga">Yoga</option>
                    <option value="Eating">Eating</option>
                    <option value="Leadership">Leadership</option>
                    <option value="5 Min Meditation">5 Min Meditation</option>
                    <option value="10 Min Meditation">10 Min Meditation</option>
                </select>
              <MindButton className={`${getUser.data?.data.darkMode ? 'dark-mode' : ''}`} onClick={() => setState("Overview")}>Overview</MindButton>
              <MindButton className={`${getUser.data?.data.darkMode ? 'dark-mode' : ''}`} onClick={() => setState("Yoga")}>Yoga</MindButton>
              <MindButton className={`${getUser.data?.data.darkMode ? 'dark-mode' : ''}`} onClick={() => setState("Eating")}>Eating</MindButton>
              <MindButton className={`${getUser.data?.data.darkMode ? 'dark-mode' : ''}`} onClick={() => setState("Leadership")}>Leadership</MindButton>
              <MindButton className={`${getUser.data?.data.darkMode ? 'dark-mode' : ''}`} onClick={() => setState("5 Min Meditation")}>5 Min Meditation</MindButton>
              <MindButton className={`${getUser.data?.data.darkMode ? 'dark-mode' : ''}`} onClick={() => setState("10 Min Meditation")}>10 Min Meditation</MindButton>
            </MindfulnessSearchBar>
            {renderer()}
            </MindfulMiddle>
        </MindfulOverlord>
    )
}

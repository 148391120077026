import React, { useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { HomeWrapper, DashRow } from "./Dashboard.styles";
import { auth, getUserID } from "../../services/firebase";
import { useMutation, useQuery } from "@tanstack/react-query";
import { User } from "../../types/User";
import { createUser, fetchUser } from "../../services/User";
import { queryClient } from "../../services/QueryClient";
import CalendarModule from "../../components/DashboardModules/CalendarModule/CalendarModule";
import StatsModule from "../../components/DashboardModules/StatsModule/StatsModule";
import AverageModule from "../../components/DashboardModules/AverageModule/AverageModule";
import SleepModule from "../../components/DashboardModules/SleepModule/SleepModule";

export function Dashboard() {
    const getUser = useQuery<User, Error>({
        queryKey: ["user"],
        queryFn: () => fetchUser(getUserID())
    });

    const userCreation = useMutation({
        mutationKey: ["user"],
        mutationFn: (body: any) => createUser(body),
        onSuccess: () => {
            console.log("Successfully created user!");
            return queryClient.invalidateQueries({ queryKey: ["user"] });
        }
    });

    //Create User
    const [once, setOnce] = useState(true);
    if (auth.currentUser && window.location.pathname !== '/') {
        if (!getUser.data && auth.currentUser.metadata.lastSignInTime === auth.currentUser.metadata.creationTime && once) {
            userCreation.mutate({
                id: auth.currentUser.uid,
                name: auth.currentUser.displayName,
                goal: 8
            });
            setOnce(false);
        }
    }

    //FOR ERROR CHECKING! You may have it return JSX.Element which will overrule current return
    if (getUser.isError) {
        console.log("Get User Error: ", getUser.error);
    }

    return (
        <HomeWrapper className={`${getUser.data?.data.darkMode ? 'dark-mode' : ''}`}>
            <div id={"firebaseui-auth-container"} style={{display: "none"}}/>
            <DashRow>
                {CalendarModule()}
                {AverageModule()}
            </DashRow>
            <DashRow>
                {SleepModule()}
                {StatsModule()}
            </DashRow>
        </HomeWrapper>
    );
}

import EditButton from "../../atoms/edit-solid.svg"
import { Modal } from "react-bootstrap";
import React, {useState} from "react";
import Swal from "sweetalert2";
import {useMutation, useQuery} from "@tanstack/react-query";
import {createRoutineEntry, deleteRoutineEntry, fetchRoutineList, updateRoutineEntry} from "../../services/Routine";
import {queryClient} from "../../services/QueryClient";
import {Routine} from "../../types/Routine";
import DarkModeSwitch from "../DarkMode/DarkModeSwitch";
import {User} from "../../types/User";
import {deleteUserID, fetchUser, updateUser} from "../../services/User";
import {
    SettingsBody,
    SettingsButton,
    SettingsCloseBtn,
    SettingsItem,
    SettingsModalStyle, SettingsOverlay, SettingsWheel,
    Goal, TaskList
} from "./settingsModal.styles";
import { IoIosSettings } from "react-icons/io";
import EmptyCheckbox from "../../atoms/square-regular.svg";
import SaveButton from "../../atoms/save-solid.svg";
import DeleteButton from "../../atoms/minus-circle-solid.svg";
import {OAUTH} from "../../services/FitbitScript";
import {auth, getUserID, logout} from "../../services/firebase";

export default function SettingsMenu() {
    const [show, setShow] = useState(false);
    const [min, setMin] = useState(0);
    const [name, setName] = useState("");
    const [editTaskId, setEditTaskId] = useState("");

    const getUser = useQuery<User, Error>({
        queryKey: ["user"],
        queryFn: () => fetchUser(getUserID())
    });

    const editUser = useMutation({
        mutationKey: ["user"],
        mutationFn: (body: any) => updateUser(body),
        onSuccess: () => {
            console.log("Successfully updated user!");
            return queryClient.invalidateQueries({queryKey: ["user"]});
        }
    });

    const deleteUser = useMutation({
        mutationKey: ["user"],
        mutationFn: (body: any) => deleteUserID(body),
        onSuccess: () => {
            console.log("Successfully deleted user!");
            auth.currentUser?.delete();
            window.location.replace(window.location.origin);
            return queryClient.invalidateQueries({queryKey: ["user"]});
        }
    });

    const createRoutine = useMutation({
        mutationKey: ["routine"],
        mutationFn: (body: any) => createRoutineEntry(body),
        onSuccess: () => {
            console.log("Successfully created bedtime routine entry!");
            return queryClient.invalidateQueries({queryKey: ["routine"]});
        }
    });

    const updateRoutine = useMutation({
        mutationKey: ["routine"],
        mutationFn: (body: any) => updateRoutineEntry(body),
        onSuccess: () => {
            console.log("Successfully updated bedtime routine entry!");
            return queryClient.invalidateQueries({queryKey: ["routine"]});
        }
    });

    const deleteRoutine = useMutation({
        mutationKey: ["routine"],
        mutationFn: (body: any) => deleteRoutineEntry(body),
        onSuccess: () => {
            console.log("Successfully deleted bedtime routine entry!");
            return queryClient.invalidateQueries({queryKey: ["routine"]});
        }
    });

    const getRoutine = useQuery<Routine[], Error>({
        queryKey: ["routine"],
        queryFn: () => fetchRoutineList(getUserID())
    });

    const editSleepGoal = () => {
       Swal.fire({
            title: "Add Sleep Goal",
            text: "Please enter the number of hours you would like to sleep each night: ",
            input: 'number', // Use `input` field for taking number input
            inputAttributes: {
                placeholder: "8",
                max: "24",
                min: "0",
                step: "0.5",
            },
            showCancelButton: true, // Shows a cancel button
        }).then((result) => {
            if (result.value) {
                const goal = result.value;

                editUser.mutate({
                    id: getUser.data?.id,
                    goal: goal
                });
                Swal.fire({
                    title: "Success",
                    icon: "success",
                    text: `Successfully set sleep goal to ${goal} hours.`,
                });
            }
        });
    }

    const editRoutineButton = (task: Routine) => {
        updateRoutine.mutate({
            id: task.id,
            minutes: min,
            name: name
        });
    }

    const getButton = (task: Routine) => {
        if (isEditing(task.id)) {
            return <img src={SaveButton} onClick={() => {
                editRoutineButton(task);
                setEditTaskId("");
            }} alt={"SaveButton"}/>
        }
    }

    const deleteRoutineButton = (id: string) => {
        deleteRoutine.mutate({id: id});
    }

    const isEditing = (taskId: string) => taskId === editTaskId;

    const listRoutine = () =>
        getRoutine.data!.map((task) => (
            <li key={task.id}>
                <img src={EmptyCheckbox} alt={"checkbox"}/>
                {isEditing(task.id) ? (
                    <>
                        <input
                            type={'text'}
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                        <input
                            type={'number'}
                            value={min}
                            onChange={e => setMin(e.target.valueAsNumber)}
                        />
                        <b>
                            {getButton(task)}
                            <img src={DeleteButton} onClick={() => deleteRoutineButton(task.id)} alt={"DeleteButton"}/>
                        </b>
                    </>
                ) : (
                    <>
                        <p>{task.data.task_name} for {task.data.minutes} minutes</p>
                        <b>
                            <img src={EditButton} onClick={() => {setEditTaskId(task.id); setName(task.data.task_name); setMin(task.data.minutes);}} alt={"EditButton"}/>
                            <img src={DeleteButton} onClick={() => deleteRoutineButton(task.id)} alt={"DeleteButton"}/>
                        </b>
                    </>
                )}
            </li>
        ));


    const addTask = () => {
        Swal.fire({
            title: "Add a Task: Enter Task",
            text: "Please enter the task you would like to add: ",
            input: 'text',
            inputAttributes: {
                placeholder: "Brush Teeth",
            },
            showCancelButton: true,
        }).then((result) => {
            const task = result.value;
            if (task) {
                if (task === "") {
                    Swal.fire({
                        text: "No task was inputted",
                        icon: "error",
                    });
                } else if(task !== null) {
                    if(task.length > 30) {
                        Swal.fire({
                            text: "Please input a task with 30 or less characters",
                            icon: "error",
                        });
                    } else {
                        Swal.fire({
                            title: "Add a Task: Enter Duration",
                            text: "Please enter the number of minutes of your new task:",
                            input: 'number',
                            inputAttributes: {
                                placeholder: "0 if the task is not timed",
                            },
                            showCancelButton: true,
                        }).then((time) => {
                            let minutes = time.value;
                            if (minutes !== null) {
                                if (minutes === "") {
                                    //user didn't enter input
                                    minutes = 0; //set minutes to zero if user doesn't input duration
                                }
                                console.log(task);
                                createRoutine.mutate({
                                    userID: getUser.data?.id,
                                    name: task,
                                    minutes: parseInt(minutes, 10) || 0
                                }, {
                                    onSuccess: () => {
                                        Swal.fire({
                                            title: "Success",
                                            icon: "success",
                                            text: "New task has been added.",
                                        });
                                    },
                                    onError: (error) => {
                                        Swal.fire({
                                            title: "Error",
                                            icon: "error",
                                            text: "Failed to add task: " + error.message,
                                        });
                                    }
                                });
                            }
                        });
                    }
                }
            }
        });
    }

    const fitbitAccess = () => {
        Swal.fire({
            title: "Do you own a Fitbit and would like to auto-fill your sleep and exercise log through Fitbit?",
            html:
                "<hr>" +
                "<p><strong>How it works:</strong></p>" +
                "<ul>" +
                "<li>We will automatically retrieve the past week's data from your Fitbit whenever you access your personal reports page.</li>" +
                "<li>Manually logging sleep and exercise will still be available, but sleep log will take precedence over Fitbit.</li>" +
                "<li style='color: red;'>Once you click the button, you will be redirected to Fitbit for authorization. However, you will have to authorize Fitbit again on the Report page on other (new) devices.</li>" +
                "</ul>",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Use FitBit",
            confirmButtonColor: "#cb1634",
            cancelButtonText: "Cancel",
            cancelButtonColor: "#b9b9b9",
        }).then((result) => {
            if (result.isConfirmed) {
                // TODO Implement Fitbit functionality
                console.log("Fit Bit");
                window.location.assign(OAUTH);
            }
        });
    }

    const signOut = () => {
        Swal.fire({
            title: "Sign Out?",
            text: "Are you sure you want to sign out?",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Sign Out",
            confirmButtonColor: "#cb1634",
            cancelButtonColor: "#b9b9b9",
        }).then((willSignOut) => {
            if (willSignOut.value) {
                logout();
            }
        });
    }

    const deleteAccount = () => {
        Swal.fire({
            //send confirmation alert
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover your account!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Delete",
            confirmButtonColor: "#cb1634",
            cancelButtonColor: "#b9b9b9",
        }).then((willDelete) => {
            if (willDelete.value) {
                if(process.env.NODE_ENV === "production") {
                    deleteUser.mutate({uid: getUserID()});
                } else {
                    console.log("Account Deleted");
                }
            }
        });
    }

    return (
        <>
            <SettingsWheel className={`${getUser.data?.data.darkMode ? 'dark-mode' : ''}`} onClick={() => setShow(!show)}>
                <IoIosSettings/>
            </SettingsWheel>
            <Modal show={show} onHide={() => setShow(!show)}>
                <SettingsOverlay/>
                <SettingsModalStyle className={`${getUser.data?.data.darkMode ? 'dark-mode' : ''}`}>
                    <SettingsCloseBtn onClick={() => setShow(!show)}>x</SettingsCloseBtn>
                    <h2>Settings</h2>
                    <SettingsBody>
                        <Goal className={`${getUser.data?.data.darkMode ? 'dark-mode' : ''}`}>
                            <h3>Sleep Goal:</h3>
                            <p>{getUser.data?.data.sleepGoal} Hours</p>
                            <img src={EditButton} onClick={() => {setShow(!show); editSleepGoal();}} alt={"EditButton"}/>
                        </Goal>
                        <h3 style = {{marginLeft: '10px'}}>Bedtime Routine:</h3>
                        <TaskList>
                            {getRoutine.data ?  listRoutine() : "Nothing to report"}
                        </TaskList>

                        <SettingsButton onClick={() => {setShow(!show); addTask();}}>
                            Add Task
                        </SettingsButton>

                        <SettingsItem onClick={fitbitAccess}>Fitbit Account Access</SettingsItem>

                        <div style={{display: 'flex', alignItems: 'center', gap: '10px', marginLeft: '10px'}}>
                            <h3>Dark Mode</h3>
                            <DarkModeSwitch/>
                        </div>

                        <SettingsItem onClick={signOut}>Sign Out</SettingsItem>
                        <SettingsItem onClick={deleteAccount}>Delete Account</SettingsItem>
                    </SettingsBody>
                </SettingsModalStyle>
            </Modal>
        </>
    );
}

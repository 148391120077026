import styled from "styled-components";
import {device} from "../../util/device";

export const HomeWrapper = styled.div`
    background: linear-gradient(180deg, #ffffff 0%, #625ad890 40%, #7339ab90 100%),
        url("/images/dawn.jpg") no-repeat center center;
    background-size: cover;
    padding-top: 7vh;
    min-height: 93vh;
    color: black;

    &.dark-mode {
        background: linear-gradient(180deg, #121212 0%, #121212 40%, #121212 100%),
            url("/images/dawn.jpg") no-repeat center center;
        color: white;
    }
`;

export const DashRow = styled.div`
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 2%;
    padding-top: 1.5%;

    @media only screen and ${device.mobileM} {
        flex-direction: column;
        height: auto;
    }

    @media only screen and ${device.tablet} {
        flex-direction: row;
        height: 50%;
    }
`;

export const MoodDiv = styled.div`
  background-color: rgba(255, 255, 255, 0);
  text-align: center;

  &.dark-mode {
    background-color: #212121;
      color: white;
  }
`;

export const DaysButton = styled.button`
    width: 80px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    border-radius: 5vw;
    height: 40px;
    color: inherit;
    
    &.active {
        background-color: rgba(75, 26, 245, 0.4);
    }

    @media only screen and ${device.mobileM} {
        margin: 0;
        font-size: 3vw;
    }

    @media only screen and ${device.tablet} {
        margin: 6px;
        font-size: 0.8vw;
    }
`;

export const ButtonLogger = styled.button`
    background-color: #457ab2;
    color: white;
    border-radius: 1.75vw;
    padding: 0.75vw 1.25vw;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;

    @media only screen and ${device.mobileM} {
        height: 45px;
        font-size: 4vw;
    }
    
    @media only screen and ${device.tablet} {
        height: 60px;
        font-size: 1.1vw;
    }
`;

export const GraphContainer = styled.div`
  padding-left: 0.5vw;
  width: 95%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const SliderButton = styled.button`
    background-color: #457AB2;
    color: white;
    border: none;
    border-radius: 2.5vh;
    padding: 1.5vh 2.5vh;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    font-size: 2vh;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 10px;
`;

import styled from "styled-components";

export const NavModalStyle = styled.div`
    @keyframes slideInFromLeft {
        from {
            transform: translateX(-100%);
        }
        to {
            transform: translateX(0);
        }
    }
    
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: auto;
    width: 270px;
    height: 100%;
    background-color: white;
    animation: slideInFromLeft 0.25s ease-out forwards;
    padding-left: 1%;
    padding-right: 1%;
    
    h2 {
        font-size: 22px;
        color: #8340dc;
        margin-bottom: 15px;
        margin-left: 10px;
    }

  &.dark-mode {
    background-color: #212121;
  }
`;

export const NavBody = styled.div`
    font-size: 16px;
    color: #666;
    line-height: 1.5;
`;

export const NavCloseBtn = styled.button`
    cursor: pointer;
    font-size: 24px;
    color: #8340dc;
    position: absolute;
    top: 15px;
    right: 25px;
    background: none;
    border: none;
`;

export const NavItem = styled.h3`
    cursor: pointer;
    margin-left: 10px;

      a{
        text-decoration: none;
        color: gray;
      }
    
    :hover {
        color: #55346a;
    }
`;

export const BurgerMenu = styled.button`
  display: none;

  @media (max-width: 768px) {
    svg {
      width: 35px;
      height: 35px;
    }
    display: block;
    width: 50px;
    padding-left: 20px;
    margin-right: 20px;
    background-color: transparent;
    color: #8340dc;
      border: none;
      cursor: pointer;
  }
`;

export const NavOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
`;

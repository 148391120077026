import {CircularProgressbar} from "react-circular-progressbar";
import SleepPopUp from "../../../atoms/sleepModal";
import React, {useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {User} from "../../../types/User";
import {fetchUser} from "../../../services/User";
import {getUserID} from "../../../services/firebase";
import SleepGraph from "../../../atoms/sleepGraph";
import MonthGraph from "../../../atoms/monthGraph";
import {Sleep} from "../../../types/Sleep";
import {fetchSleepList} from "../../../services/Sleep";
import {LogSleepButton, SleepDiv, SleepGraphDiv, SleepInfo, ToggleSleep} from "./SleepModule.styles";

export default function SleepModule() {
    const [isSleepModalOpen, setIsSleepModalOpen] = useState(false);
    const [sleepToggle, setSleepToggle] = useState("Week");

    const getUser = useQuery<User, Error>({
        queryKey: ["user"],
        queryFn: () => fetchUser(getUserID())
    });

    const getSleep = useQuery<Sleep[], Error>({
        queryKey: ["sleep"],
        queryFn: () => fetchSleepList(getUserID())
    });

    const renderSleepGraph = (option: string) => {
        switch (option) {
            case "Week":
                return (SleepGraph());
            case "Month":
                return (MonthGraph());
            default:
                return null;
        }
    };

    let sleepSum = 0;
    let sleepAvg = '0';
    let sleepData;
    if (getSleep.isSuccess) {
        const currDate = new Date();
        sleepData = getSleep.data.slice(0, sleepToggle === "Week" ? 7 : 30).filter((a) => {
            if (sleepToggle === "Week") {
                return new Date(a.data.end_sleep) > new Date(currDate.getFullYear(), currDate.getMonth(), currDate.getDate() - 7);
            } else {
                return new Date(a.data.end_sleep) > new Date(currDate.getFullYear(), currDate.getMonth(), currDate.getDate() - 30);
            }
        });
        sleepSum = sleepData.reduce((acc, entry) => acc + (new Date(entry.data.end_sleep).getTime() - new Date(entry.data.start_sleep).getTime()) / (1000 * 60 * 60), 0);
    }

    if (getSleep.data) {
        sleepAvg = (sleepSum / sleepData.length).toFixed(1);
    }

    let sleepProgress = 0;
    if (getUser.data?.data.sleepGoal) {
        sleepProgress = parseInt((parseInt(sleepAvg) / getUser.data?.data.sleepGoal * 100).toFixed(0));

        if (sleepProgress > 100) {
            sleepProgress = 100;
        } else if (sleepProgress < 0) {
            sleepProgress = 0;
        }
    }

    return (
        <SleepDiv className={`${getUser.data?.data.darkMode ? 'dark-mode' : ''}`}>
            <SleepInfo>
                {sleepProgress ? <CircularProgressbar value={sleepProgress} text={sleepProgress + `%`} className={"progressbar"}/> : <>No Data</>}
                <span>
                    Avg. Sleep
                    <p>{sleepAvg !== "NaN" ? sleepAvg + " hrs" : "No Data"}</p>
                </span>
                <LogSleepButton onClick={() => setIsSleepModalOpen(true)}>Log Sleep</LogSleepButton>
            </SleepInfo>
            <SleepGraphDiv>
                <ToggleSleep
                    className={sleepToggle === "Week" ? "active" : ""}
                    onClick={() => setSleepToggle("Week")}
                >
                    7 Days
                </ToggleSleep>
                <ToggleSleep
                    className={sleepToggle === "Month" ? "active" : ""}
                    onClick={() => setSleepToggle("Month")}
                >
                    30 Days
                </ToggleSleep>
                {renderSleepGraph(sleepToggle)}
                <SleepPopUp isOpen={isSleepModalOpen} onClose={() => setIsSleepModalOpen(false)}/>
            </SleepGraphDiv>
        </SleepDiv>
    );
}

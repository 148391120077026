import { useState } from 'react';
import { Slider } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { createStressEntry, updateStressEntry } from '../services/Stress';
import { queryClient } from '../services/QueryClient';
import { getUserID } from "../services/firebase";

export default function StressCalendarLogger({ selectedEvent, handleEventClose }) {

    const [editingStressLevel, setEditingStressLevel] = useState(selectedEvent.stressLevel === null);
    const [stressLevel, setStressLevel] = useState(selectedEvent.stressLevel || 0);
    const [prevEntry, setPrevEntry] = useState(selectedEvent.stressLevel !== null && !isNaN(selectedEvent.stressLevel));

    const createStress = useMutation({
        mutationKey: ["stress"],
        mutationFn: (body: any) => createStressEntry(body),
        onSuccess: () => {
            console.log("Successfully created stress entry!");
            return queryClient.invalidateQueries({ queryKey: ["stress"] });
        }
    });

    const handleSliderChange = (event: any, newValue: any) => {
        setStressLevel(newValue);
    };

    // Handle close and ensure parent component is notified
    const onEventClose = () => {
        handleEventClose(stressLevel);
    };

    // set fields from selected event
    const eventTitle = selectedEvent.eventName;
    const eventDescription = selectedEvent.description || "No description available";
    const eventTime = `${selectedEvent.startTime} - ${selectedEvent.endTime}`;
    const eventLocation = selectedEvent.location || "Not specified";

    const onStressSubmit = (stressLevel) => {
        const stressBody = {
            //userID: {getUserID},
            userID: getUserID(),
            stress: stressLevel,
            event: eventTitle,
            date: selectedEvent.date,
            apiID: selectedEvent.apiID,
        }
        if (!prevEntry) {
            alert("Creating Stress Entry");
            console.log(stressBody)
            setEditingStressLevel(!editingStressLevel)
            createStress.mutate(stressBody)
            setStressLevel(stressBody.stress)
        }
        else {
            alert("Updated Stress Entry");
            setEditingStressLevel(!editingStressLevel)
            updateStressEntry(stressBody)
            setStressLevel(stressBody.stress)

        }


    }


    return (
        <>
                {editingStressLevel ? (
                    <div style={{ position: "relative", display: "flex", flexDirection: "column", gap: "20px", marginTop: "10px", padding: "16px", backgroundColor: "#671bca", borderRadius: "8px", color: "white" }}>
                        <div style={{ position: "absolute", top: "14px", right: "14px", cursor: "pointer", fontSize: "16px" }} onClick={onEventClose}> X</div>
                        <div style={{ fontSize: "16px" }}>{"Stress Level: " + stressLevel}</div>
                        <Slider
                            style={{ display: "flex", width: "90%", margin: "0 auto", color: "#4a0c9c", flex: "1", padding: "10px", fontSize: "10px" }}
                            value={stressLevel}
                            onChange={handleSliderChange}
                            aria-labelledby="continuous-slider"
                            valueLabelDisplay="auto"
                            min={0}
                            max={100}
                            step={10}
                            marks={[
                                {
                                    value: 0,
                                    label: '0',
                                },
                                {
                                    value: 50,
                                    label: '50',
                                },
                                {
                                    value: 100,
                                    label: '100',
                                },
                            ]}
                        />
                        <button onClick={() => onStressSubmit(stressLevel)} style={{ marginTop: "50px", backgroundColor: '#4a0c9c', color: "white", padding: '10px 20px', borderRadius: '20px', fontWeight: "bold", cursor: 'pointer' }}>Log Stress</button>
                    </div>
                ) :
                    <div style={{ display: 'flex', position: "relative", flexDirection: 'column', gap: '8px', padding: '14px',      borderRadius: '10px', color: 'white', backgroundColor: '#8340dc', borderLeft: '6px solid #671bca', height: "100%" }}>
                        <div style={{ position: "absolute", top: "14px", right: "14px", cursor: "pointer", fontSize: "16px" }} onClick={onEventClose}> X</div>

                        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "14px" }}>
                            <div style={{ fontSize: '20px', fontWeight: "bold" }}> {eventTitle} </div>
                        </div>

                        {stressLevel !== null && !editingStressLevel &&
                            <div style={{ display: "flex", fontSize: '12px', alignSelf: "flex-start", }}>
                                <div style={{ display: "flex", flex: "1", gap: "10px", fontSize: '16px', alignItems: "center", border: "1px solid white", borderRadius: '10px', padding: '6px 8px', fontWeight: "bold", backgroundColor: "#671bca" }}>
                                    {"Stress Level: " + stressLevel}
                                </div>
                            </div>
                        }

                        <div style={{ display: "flex", gap: "10px", fontSize: '16px', marginTop: "10px", alignItems: "center" }}>
                            <img src="Images/time.svg" alt="" style={{ fill: "white" }} />
                            {eventTime}
                        </div>

                        <div style={{ display: "flex", gap: "10px", fontSize: '16px', marginTop: "10px", alignItems: "center" }}>
                            <img src="Images/location.svg" alt="" style={{ fill: "white" }} />
                            {eventLocation}
                        </div>

                        <div style={{ display: "flex", gap: "10px", fontSize: '16px', marginTop: "10px", justifyContent:    "flex-start", }}>
                            <img src="Images/description.svg" alt="" style={{ fill: "white", alignSelf: "flex-start" }} />
                            {eventDescription}
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <button
                                onClick={() => setEditingStressLevel(!editingStressLevel)}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: '#671bca',
                                    color: "white",
                                    padding: '12px 12px',
                                    borderRadius: '20px',
                                    fontWeight: 'bold',
                                    cursor: 'pointer',
                                    transition: 'background-color 0.3s ease-in-out',
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                                }}
                                onMouseEnter={(e: React.MouseEvent<HTMLButtonElement>) => { e.currentTarget.style.backgroundColor = '#4a0c9c'; }}
                                onMouseLeave={(e: React.MouseEvent<HTMLButtonElement>) => { e.currentTarget.style.backgroundColor = '#671bca'; }}
                            >
                                {prevEntry ? "Update Stress Level" : "Log Stress Level"}
                            </button>
                        </div>
                    </div >
                }
        </>
    );
}

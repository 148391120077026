import {Tab} from "semantic-ui-react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import DirectionsRun from "@mui/icons-material/DirectionsRun";
import AccessTime from "@mui/icons-material/AccessTime";
import React, {useState} from "react";
import {styled} from "@mui/material/styles";
import Slider from "@mui/material/Slider";
import {createExerciseEntry} from "../services/Exercise";
import {queryClient} from "../services/QueryClient";
import {useMutation} from "@tanstack/react-query";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {getUserID} from "../services/firebase";
import { SliderButton } from "../pages/Dashboard/Dashboard.styles";

const PrettoSlider = styled(Slider)(({ theme }) => ({
    color: 'mediumpurple',
    height: 8,
    '& .MuiSlider-thumb': {
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        height: '3.25vh',
        width: '3.25vh',
    },
    '& .MuiSlider-valueLabel': {
        left: 'calc(-50% + 4px)',
    },
    '& .MuiSlider-track': {
        height: '2.5vh',
        width: '25vw',
        borderRadius: '2.5vh',
    },
    '& .MuiSlider-rail': {
        height: '2.5vh',
        width: '25vw',
        borderRadius: '2.5vh',
    },
}));

const ExerciseSlider = ({ onClose }) => {
    const [intensity, setIntensity] = useState(3); // Default bottle size
    const [mins, setMins] = useState(0); // Default number of bottles
    const [exerciseTime, setExerciseTime] = useState(new Date()); // State to store the datetime value

    // Function to handle changes in the datetime input field
    const handleExerciseTimeChange = (date: Date) => {
        setExerciseTime(date);
    };

    const handleExerciseMinsChange = (event, newValue) => {
        setMins(newValue);
    };

    const handleIntensityLvlChange = (event, newValue) => {
        setIntensity(newValue);
    };


    const createExercise = useMutation({
        mutationKey: ["exercise"],
        mutationFn: (body: any) => createExerciseEntry(body),
        onSuccess: () => {
            console.log("Successfully created exercise entry!");
            return queryClient.invalidateQueries({queryKey: ["exercise"]});
        }
    });

    const intensityLevel = [
        {value: 1, label: "Low",},
        {value: 2, label: "",},
        {value: 3, label: "Moderate",},
        {value: 4, label: "",},
        {value: 5, label: "High",},
    ];

    const minutes = [
        {value: 2, label: '0',},
        {value: 45, label: '45',},
        {value: 90, label: '90',},
        {value: 135, label: '135',},
        {value: 178, label: '180',},
    ];

    const handleEnterExercise = async () => {
        const correctedDate = `${exerciseTime.getFullYear()}-${exerciseTime.getMonth()+1}-${exerciseTime.getDate()}T00:00:00.000Z`;

        const body = {
            userID: getUserID(),
            date: correctedDate, // Use the captured datetime value
            minutes: mins,
            avg_intensity: intensity,
        };

        try {
            await createExercise.mutateAsync(body);
            onClose();
        } catch (error) {
            console.error("Error creating exercise entry:", error);
            // Handle error
        }
    };

    return (
        <>
            <div style={{
                display: "flex",
                justifyContent: 'center',
            }}>
                <Tab.Pane attached={false} style={{fontSize: '2vh'}}>
                    <h5 style={{fontSize: '2.75vh'}}>Rate intensity & duration of exercise</h5>
                    <Typography id="discrete-slider-restrict" gutterBottom style={{fontSize: '2.5vh'}}>
                        Average Intensity
                    </Typography>
                    <Grid container spacing={'1vw'} style={{fontSize: '2.75vh'}}>
                        <Grid item>
                            <DirectionsRun style={{height: '5vh', width: '5vh'}}/>
                        </Grid>
                        <Grid item xs style={{width: '25vw'}}>
                            <PrettoSlider
                                id="intensity"
                                aria-label="pretto slider"
                                defaultValue={1}
                                step={1}
                                min={1}
                                max={5}
                                marks={intensityLevel}
                                onChange={handleIntensityLvlChange}
                            />
                        </Grid>
                    </Grid>
                    <br/>
                    <Typography id="discrete-slider-restrict" gutterBottom>
                        Duration of Exercise (minutes)
                    </Typography>
                    <Grid container spacing={'1vw'}>
                        <Grid item>
                            <AccessTime style={{height: '5vh', width: '5vh'}}/>
                        </Grid>
                        <Grid item xs style={{width: '25vw'}}>
                            <PrettoSlider
                                id="minutes"
                                aria-label="pretto slider"
                                defaultValue={90}
                                valueLabelDisplay="auto"
                                step={15}
                                min={0}
                                max={180}
                                marks={minutes}
                                onChange={handleExerciseMinsChange}
                            />
                        </Grid>
                    </Grid>
                    Click the date to change it!
                    <DatePicker selected={exerciseTime} onChange={handleExerciseTimeChange}/>
                </Tab.Pane>
            </div>
            <SliderButton onClick={handleEnterExercise}>
                Enter Exercise
            </SliderButton>
        </>
    );
};

export default ExerciseSlider;

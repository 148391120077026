import {DaysButton} from "../../../pages/Dashboard/Dashboard.styles";
import React, {useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {User} from "../../../types/User";
import {fetchUser} from "../../../services/User";
import {getUserID} from "../../../services/firebase";
import {Caffeine} from "../../../types/Caffeine";
import {fetchCaffeineList} from "../../../services/Caffeine";
import {Exercise} from "../../../types/Exercise";
import {fetchExerciseList} from "../../../services/Exercise";
import {Mood} from "../../../types/Mood";
import {fetchMoodList} from "../../../services/Mood";
import {Sleep} from "../../../types/Sleep";
import {fetchSleepList} from "../../../services/Sleep";
import {Stress} from "../../../types/Stress";
import {fetchStressList} from "../../../services/Stress";
import {Water} from "../../../types/Water";
import {fetchWaterList} from "../../../services/Water";
import {AvgBox, AvgChoices, AvgDiv, AvgList} from "./AverageModule.styles";

export default function AverageModule() {
    const [selectedDuration, setSelectedDuration] = useState(7);

    const getUser = useQuery<User, Error>({
        queryKey: ["user"],
        queryFn: () => fetchUser(getUserID())
    });

    const getCaffeine = useQuery<Caffeine[], Error>({
        queryKey: ["caffeine"],
        queryFn: () => fetchCaffeineList(getUserID())
    });

    const getExercise = useQuery<Exercise[], Error>({
        queryKey: ["exercise"],
        queryFn: () => fetchExerciseList(getUserID())
    });

    const getMood = useQuery<Mood[], Error>({
        queryKey: ["mood"],
        queryFn: () => fetchMoodList(getUserID())
    });

    const getSleep = useQuery<Sleep[], Error>({
        queryKey: ["sleep"],
        queryFn: () => fetchSleepList(getUserID())
    });

    const getStress = useQuery<Stress[], Error>({
        queryKey: ["stress"],
        queryFn: () => fetchStressList(getUserID())
    });

    const getWater = useQuery<Water[], Error>({
        queryKey: ["water"],
        queryFn: () => fetchWaterList(getUserID())
    });

    let caffeineSum = 0, exerciseSum = 0, stressSum = 0, waterSum = 0;
    let caffeineAvg = '0', exerciseAvg = '0', moodMode = '0', stressAvg = '0', waterAvg = '0';
    let caffeineData, exerciseData, stressData, waterData;
    if (
        getUser.isSuccess &&
        getCaffeine.isSuccess &&
        getExercise.isSuccess &&
        getSleep.isSuccess &&
        getStress.isSuccess &&
        getWater.isSuccess
    ) {
        const currDate = new Date();
        const compare = new Date(currDate.getFullYear(), currDate.getMonth(), currDate.getDate() - selectedDuration);
        caffeineData = getCaffeine.data.slice(0, selectedDuration).filter((a) => {
            return new Date(a.data.date) > compare;
        });
        exerciseData = getExercise.data.slice(0, selectedDuration).filter((a) => {
            return new Date(a.data.date) > compare;
        });
        stressData = getStress.data.slice(0, selectedDuration).filter((a) => {
            return new Date(a.data.date) > compare;
        });
        waterData = getWater.data.slice(0, selectedDuration).filter((a) => {
            return new Date(a.data.date) > compare;
        });
        // Calculate averages based on fetched data
        caffeineSum = caffeineData.reduce((acc, entry) => acc + entry.data.cups, 0);
        exerciseSum = exerciseData.reduce((acc, entry) => acc + entry.data.minutes, 0);
        stressSum = stressData.reduce((acc, entry) => acc + entry.data.stress, 0);
        waterSum = waterData.reduce((acc, entry) => acc + entry.data.cups, 0);
    }

    if (getCaffeine.data && getExercise.data && getMood.data && getStress.data && getSleep.data && getWater.data) {
        caffeineAvg = (caffeineSum / caffeineData.length).toFixed(1);
        exerciseAvg = (exerciseSum / exerciseData.length).toFixed(1);
        stressAvg = (stressSum / stressData.length).toFixed(1);
        waterAvg = (waterSum / waterData.length).toFixed(1);
    }

    //calcs most frequent mood
    if (getMood.data) {
        const currDate = new Date();
        const compare = new Date(currDate.getFullYear(), currDate.getMonth(), currDate.getDate() - selectedDuration);
        const moodOccurrences = {};
        getMood.data.slice(0, selectedDuration).filter((a) => {
            return new Date(a.data.date) > compare;
        }).forEach((entry) => {
            const mood = entry.data.mood;
            if (moodOccurrences[mood]) {
                moodOccurrences[mood]++;
            } else {
                moodOccurrences[mood] = 1;
            }
        });

        let maxOccurrences = 0;
        for (const mood in moodOccurrences) {
            if (moodOccurrences[mood] > maxOccurrences) {
                moodMode = mood;
                maxOccurrences = moodOccurrences[mood];
            }
        }
    }

    let mfMood = 'No Data';

    if (moodMode === '1') {
        mfMood = 'Happy 😆';
    } else if (moodMode === '2') {
        mfMood = 'Satisfied 🙂';
    } else if (moodMode === '3') {
        mfMood = 'Relaxed 😌';
    } else if (moodMode === '4') {
        mfMood = 'Sad 😢';
    } else if (moodMode === '5') {
        mfMood = 'Angry 😡';
    } else {
        //oopsies
    }

    return (
        <AvgDiv className={`${getUser.data?.data.darkMode ? 'dark-mode' : ''}`}>
            <AvgChoices>
                <DaysButton onClick={() => setSelectedDuration(7)}
                            className={selectedDuration === 7 ? "active" : ""}>
                    7 Days
                </DaysButton>
                <DaysButton onClick={() => setSelectedDuration(30)}
                            className={selectedDuration === 30 ? "active" : ""}>
                    30 Days
                </DaysButton>
                <DaysButton onClick={() => setSelectedDuration(100)}
                            className={selectedDuration === 100 ? "active" : ""}>
                    All Time
                </DaysButton>
            </AvgChoices>
            <AvgList>
                <AvgBox>
                    Avg Water (cups)
                    <p>{waterAvg}</p>
                </AvgBox>
                <AvgBox>
                    Avg Stress lvl
                    <p>{stressAvg}</p>
                </AvgBox>
                <AvgBox>
                    Avg Caffeine (cups)
                    <p>{caffeineAvg}</p>
                </AvgBox>
                <AvgBox>
                    Avg Exercise (min)
                    <p>{exerciseAvg}</p>
                </AvgBox>
                <AvgBox>
                    Most Frequent Mood
                    <p>{mfMood}</p>
                </AvgBox>
            </AvgList>
        </AvgDiv>
    );
}

import {fetcher} from "./Fetcher";
import {mutator} from "./Mutator";
import {Caffeine} from "../types/Caffeine";

export function fetchCaffeineList(uid: string) {
    return fetcher<Caffeine[]>(`/caffeine/${uid}`, "GET");
}

export function createCaffeineEntry(body?: any | null) {
    return mutator("/caffeine", "POST", body);
}

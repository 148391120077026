import axios, {AxiosResponse} from "axios";

const base = process.env.REACT_APP_API_URL;

export const fetcher = async <T>(url: string, method: string): Promise<T> => {
    const axiosInstance = axios.create({
        baseURL: base,
        timeout: 5000
    });

    //May want to do an idToken with authorization so that no one can just simply query our server

    const res = await axiosInstance({
        url: url,
        method,
        headers: {"Content-Type": "application/json"}
    }).then((res: AxiosResponse) => res.data);

    return new Promise((resolve, reject) =>
        setTimeout(() => {
            if (res.status !== 500) {
                resolve(res as T);
            } else {
                reject(res);
            }
        }, 1)
    );
}

import React, {useState} from 'react';
import { Bar } from "react-chartjs-2";
import {ButtonLogger, DaysButton, GraphContainer} from "../pages/Dashboard/Dashboard.styles";
import {ScriptableContext} from "chart.js";
import StatsPopUp from "./statsModal";
import { useQuery } from "@tanstack/react-query";
import {Caffeine} from "../types/Caffeine";
import {fetchCaffeineList} from "../services/Caffeine";
import {User} from "../types/User";
import {fetchUser} from "../services/User";
import {getUserID} from "../services/firebase";


const isMobile = window.matchMedia("(max-width: 768px)").matches;
let labelSize = '10vw';


export default function CaffeineGraph() {
    const [isStatsModalOpen, setIsStatsModalOpen] = useState(false);
    const [selectedDuration, setSelectedDuration] = useState(7);
    const openStatsModal = () => {
        setIsStatsModalOpen(true);
    };

    const closeStatsModal = () => {
        setIsStatsModalOpen(false);
    };

    const getUser = useQuery<User, Error>({
        queryKey: ["user"],
        queryFn: () => fetchUser("1234567890")
    });

    const getCaffeine = useQuery<Caffeine[], Error>({
        queryKey: ["caffeine"],
        queryFn: () => fetchCaffeineList(getUserID())
    });

    let caffeineCupsData, caffeineDateData;

    if (getCaffeine.data) {
        // Take the 5 most recent dates
        const currDate = new Date();
        const compare = new Date(currDate.getFullYear(), currDate.getMonth(), currDate.getDate() - selectedDuration);
        const recentCaffeineData = getCaffeine.data.slice(0, selectedDuration).reverse().filter((a) => {
            return new Date(a.data.date) > compare;
        });

        // Extract hours and dates from the recent data
        caffeineCupsData = recentCaffeineData.map(caffeine => caffeine.data.cups);
        caffeineDateData = recentCaffeineData.map(caffeine => {const parsedDate = new Date(caffeine.data.date);
            return `${parsedDate.getMonth() + 1}/${parsedDate.getDate()}`;
        });
    }

    const options = {
        plugins: {
            legend: {
                onClick: () => {},
                labels: {
                    font: {
                        size: (context) => {
                            return isMobile ? 12 : 16; // Adjust font size based on device
                        }
                    }
                }
            }
        },
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false,
                },
                ticks : {
                    color: getUser.data?.data.darkMode ? '#8a8a8a' : 'rgba(0,0,0,1)',
                    font: {
                        size: parseInt(labelSize) // Parse the labelSize string into a number
                    }
                }
            },
            y: {
                ticks: {
                    color: getUser.data?.data.darkMode ? '#8a8a8a' : 'rgba(0,0,0,1)',
                    beginAtZero: true,
                    font: {
                        size: parseInt(labelSize) // Parse the labelSize string into a number
                    }
                },
                grid: {
                    display: false,
                }
            },
        },
    };

    const data = {
        labels: caffeineDateData,
        datasets: [
            {
                borderSkipped: false,
                label: "Caffeine (cups)",
                backgroundColor: (context: ScriptableContext<"line">) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 0, 0, 300);
                    const c1 = "rgb(142,142,204)";
                    const c2 = "rgb(93,93,190)";
                    gradient.addColorStop(0, c1);
                    gradient.addColorStop(1, c2);
                    return gradient;
                },
                borderWidth: 0,
                borderRadius: 0.012*window.innerWidth,
                data: caffeineCupsData,
            },
            // {
            //     label: 'Goal',
            //     borderSkipped: false,
            //     data:[2, 2, 2, 2, 2],
            //     backgroundColor: 'white',
            //     borderRadius: 0.012*window.innerWidth,
            // }
        ],

    };

    return (
        <GraphContainer>
            <div style={{
                display: "flex",
                justifyContent: "end"
            }}>
                <DaysButton onClick={() => setSelectedDuration(7)} className={selectedDuration === 7 ? "active" : ""}>7 Days</DaysButton>
                <DaysButton onClick={() => setSelectedDuration(30)} className={selectedDuration === 30 ? "active" : ""}>30 Days</DaysButton>
            </div>
            <div style={{
                margin: 0,
                width: "85%",
                marginLeft: "auto",
                marginRight: "auto"
            }}>
                {/*@ts-ignore*/}
                <Bar options={options} data={data}></Bar>
            </div>

            <ButtonLogger onClick={openStatsModal}>Log Caffeine</ButtonLogger>
            <StatsPopUp index={0} isOpen={isStatsModalOpen} onClose={closeStatsModal} />
        </GraphContainer>
    );
}


import React, { useState } from 'react';
import { styled } from "@mui/material/styles";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import OpacityIcon from "@mui/icons-material/Opacity";
import Battery90Icon from "@mui/icons-material/Battery90";
import { Tab } from "semantic-ui-react";
import {createWaterEntry} from "../services/Water";
import {queryClient} from "../services/QueryClient";
import {useMutation} from "@tanstack/react-query";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {getUserID} from "../services/firebase";
import {SliderButton} from "../pages/Dashboard/Dashboard.styles";

// Styled component for the sliders
const PrettoSlider = styled(Slider)(({ theme }) => ({
    color: 'mediumpurple',
    '& .MuiSlider-thumb': {
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        height: '3.25vh',
        width: '3.25vh',
    },
    '& .MuiSlider-track': {
        height: '2.5vh',
        width: '25vw',
        borderRadius: '2.5vh',
    },
    '& .MuiSlider-rail': {
        height: '2.5vh',
        width: '25vw',
        borderRadius: '2.5vh',
    },
}));

const WaterSlider = ({ onClose }) => {
    const [size, setSize] = useState(50); // Default bottle size
    const [number, setNumber] = useState(0); // Default number of bottles
    const [waterTime, setWaterTime] = useState(new Date()); // State to store the datetime value

    // Function to handle changes in the datetime input field
    const handleWaterTimeChange = (date: Date) => {
        setWaterTime(date);
    };
    const cupSizeMarks = [
        { value: 6, label: '6 oz' },
        { value: 12, label: '12 oz' },
        { value: 18, label: '18 oz' },
    ];

    const numberMarks = [
        { value: 0, label: '0' },
        { value: 2, label: '2' },
        { value: 4, label: '4' },
        { value: 6, label: '6' },
        { value: 8, label: '8' },
    ];

    // Function to handle changes in water intake visualization
    const handleWaterIntakeChange = (event, newValue) => {
        setNumber(newValue);
    };

    // Function to handle changes in bottle size
    const handleBottleSizeChange = (event, newValue) => {
        setSize(newValue);
    };

    const createWater = useMutation({
        mutationKey: ["water"],
        mutationFn: (body: any) => createWaterEntry(body),
        onSuccess: () => {
            console.log("Successfully created water entry!");
            return queryClient.invalidateQueries({queryKey: ["water"]});
        }
    });

    const handleEnterWater = async () => {
        const correctedDate = `${waterTime.getFullYear()}-${waterTime.getMonth()+1}-${waterTime.getDate()}T00:00:00.000Z`;

        const body = {
            userID: getUserID(),
            cups: number,
            date: correctedDate, // Use the captured datetime value
            avg_size: size,
        };

        try {
            createWater.mutate(body);
            onClose();
        } catch (error) {
            console.error("Error creating water entry:", error);
            // Handle error
        }
    };


    return (
        <>
            <div style={{
                display: "flex",
                justifyContent: 'center',
            }}>
                <Tab.Pane attached={false} style={{fontSize: '2vh'}}>
                    <h5 style={{fontSize: '2.75vh'}}>Log the amount of water consumed</h5>
                    <Typography gutterBottom style={{fontSize: '2.5vh'}}>Water Bottle Size</Typography>
                    <Grid container spacing={'1vw'}>
                        <Grid item>
                            <OpacityIcon style={{height: '5vh', width: '5vh'}}/>
                        </Grid>
                        <Grid item xs style={{width: '25vw'}}>
                            <PrettoSlider
                                id="cupSize"
                                aria-label="pretto slider"
                                defaultValue={50}
                                step={6}
                                min={6}
                                max={18}
                                marks={cupSizeMarks}
                                valueLabelDisplay="auto"
                                onChange={handleBottleSizeChange}

                            />
                        </Grid>
                    </Grid>
                    <br/>
                    <Typography gutterBottom style={{fontSize: '2.5vh'}}># of Bottles</Typography>
                    <Grid container spacing={'1vw'}>
                        <Grid item>
                            <Battery90Icon style={{height: '5vh', width: '5vh'}}/>
                        </Grid>
                        <Grid item xs style={{width: '25vw'}}>
                            <PrettoSlider
                                id="cups"
                                aria-label="pretto slider"
                                defaultValue={0}
                                step={1}
                                min={0}
                                max={8}
                                marks={numberMarks}
                                valueLabelDisplay="auto"
                                onChange={handleWaterIntakeChange}
                            />
                        </Grid>
                    </Grid>
                    Click the date to change it!
                    <DatePicker selected={waterTime} onChange={handleWaterTimeChange}/>
                </Tab.Pane>
            </div>
            <SliderButton onClick={handleEnterWater}>
                Enter Water
            </SliderButton>
        </>

    );
};
export default WaterSlider;

import React from "react";
import "../../styles/awesome-bootstrap-checkbox-master/awesome-bootstrap-checkbox.css";
import "../../styles/ItsBedtime.css";

export default function MindfulYoga() {
    return (
      <div>
        <br />
        <br />
        <iframe
            width="550"
            style={{ maxWidth: '100%', aspectRatio: '16 / 16' }}
          src="https://www.youtube.com/embed/9PPO7mWRRD4"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
        <br />
        <p>
          <b>Video Description:</b> In this video, the camera follows Jenny
          more closely as she does the chair modification of each pose while Dr.
          Lynn Rossy, Health Psychologist for Healthy for Life guides you
          through the practice of mindful yoga. 
        </p>
        <p>
        <a href="https://www.umassmed.edu/psychiatry/resources/mindfulness/resources/">
          Source
        </a>
        </p>
        <br />
        <hr className="hr-settings" />
        <br />
        <p>Spotify Yoga Playlist</p>
        <br />
        <a href="https://open.spotify.com/playlist/37i9dQZF1DX9uKNf5jGX6m">
        <img src="https://media.gettyimages.com/id/723512677/photo/silhouette-of-woman-on-beach-during-sunset.jpg?s=612x612&w=0&k=20&c=N4IbVw8Fz0vPoctXdkUyXu0a4NXyY_5TiWU2mwarhcU=" 
        alt="Spotify Yoga Playlist" width="550" height="400"/>
        </a>
      </div>
    );
}

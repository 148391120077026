import React from "react";
import "../../styles/awesome-bootstrap-checkbox-master/awesome-bootstrap-checkbox.css";
import "../../styles/ItsBedtime.css";

export default function FiveMinMind() {
    return (
      <div>
        <br />
        <br/>
        <iframe
            width="550"
            style={{ maxWidth: '100%', aspectRatio: '16 / 16' }}
          scrolling="no"
          frameBorder="no"
          allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/665221820&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
        />
        <p>
          <b>Audio Description:</b> This audio from the UCSD Center for Mindfulness will lead you in a 5
          minute mindfulness meditation exercise.
        </p>
        <p>
        <a href="https://cih.ucsd.edu/mindfulness/guided-audio-video">
          Source
        </a>
        </p>
        <br />
        <hr className="hr-settings" />
        
        <br/>
        <p>Spotify Peaceful Meditation Playlist</p>
        <br/>
        <a href="https://open.spotify.com/playlist/37i9dQZF1DWZqd5JICZI0u">
        <img src="https://media.gettyimages.com/id/484370054/photo/easy-pose-tranquil-lakeside-meditation-at-sunrise.jpg?s=612x612&w=0&k=20&c=HJOPyADCXH97CiaPZ3Npr-6nMQBKt7AMSBnJ5WK-xC4="
        alt="Spotify Peaceful Meditation Playlist" width="550" height="400" style={{ maxWidth: '100%', height: 'auto' }} />
        </a>
      </div>
    );
}

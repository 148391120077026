import React from "react";

export default function NotFound() {
    return (
        <>
            <div id={"firebaseui-auth-container"} style={{ display: "none" }} />
            <div style={{textAlign: "center", fontSize:"40px", paddingTop:"20%"}}>
                Sorry, the page you are looking for does not exist or cannot be found.
            </div>
        </>

    );
}

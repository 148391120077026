import React from 'react';
import Modal from 'react-modal';
import "react-datepicker/dist/react-datepicker.css";
import WaterSlider from "./waterSlider";
import ExerciseSlider from "./exerciseSlider";
import CaffeineSlider from "./caffineSlider";
import {useQuery} from "@tanstack/react-query";
import {User} from "../types/User";
import {fetchUser} from "../services/User";

Modal.setAppElement('#root');

const StatsPopUp = ({ index, isOpen, onClose }) => {

    const handleClose = () => {
        onClose();
    };

    const getUser = useQuery<User, Error>({
        queryKey: ["user"],
        queryFn: () => fetchUser("1234567890")
    });

    const carouselContent = [
        <CaffeineSlider key="caffeine" onClose={handleClose}/>,
        <WaterSlider key="water" onClose={handleClose}/>,
        <ExerciseSlider key="exercise" onClose={handleClose}/>,
    ];

    // Add media query for mobile screens
    const isMobile = window.matchMedia("(max-width: 768px)").matches;

    if (isMobile) {
        return (
            <>
                <style>{`
                @keyframes popOut {
                  0% {
                    transform: translate(-50%, -50%) scale(0.5);
                  }
                  100% {
                    transform: translate(-50%, -50%) scale(1);
                  }
                }
            `}</style>

                <Modal
                    isOpen={isOpen}
                    onRequestClose={onClose}
                    contentLabel="Stats Popup"
                    style={{
                        overlay: {
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            zIndex: '1000',
                        },
                        content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            width: '80%',
                            height: '50%',
                            borderRadius: '2.5vw',
                            // minWidth: '700px',
                            // minHeight: '450px',
                            animation: `popOut 0.5s ease-out forwards`,
                            backgroundColor: getUser.data?.data.darkMode ? 'black' : 'white',
                            color: getUser.data?.data.darkMode ? 'white' : 'black'
                        },
                    }}
                >
                    <div
                        style={{
                            position: 'absolute',
                            top: '1.5vh',
                            right: '2.5vh',
                            cursor: 'pointer',
                            fontSize: '5vh',
                            fontWeight: 'lighter',
                        }}
                        onClick={handleClose}
                    >
                        x
                    </div>
                    <div>
                        {carouselContent[index]}
                    </div>
                </Modal>
            </>
        );
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onRequestClose={onClose}
                contentLabel="Example Popup"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: '1000',
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '50%',
                        height: '50%',
                        borderRadius: '2.5vw',
                        // minWidth: '700px',
                        // minHeight: '450px',
                        animation: `popOut 0.5s ease-out forwards`,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        position: 'relative',
                        backgroundColor: getUser.data?.data.darkMode ? "#212121" : "white",
                        color: getUser.data?.data.darkMode ? "white" : "black"
                    },
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: '1.5vh',
                        right: '2.5vh',
                        cursor: 'pointer',
                        fontSize: '5vh',
                        fontWeight: 'lighter',
                        color: 'gray',
                    }}
                    onClick={handleClose}
                >
                    x
                </div>
                <div>
                    {carouselContent[index]}
                </div>
            </Modal>
        </>
    );
};

export default StatsPopUp;

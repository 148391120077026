import styled from "styled-components";

export const MindfulOverlord = styled.div`
  background-size: cover;
  height: inherit;
  width: 100vw;
  overflow: auto;
  display: table;
  z-index: 7;

  background: linear-gradient(
          180deg,
          #1f9ce490 0%,
          #625ad890 40%,
          #7339ab90 100%
  ), url("/images/zen.jpg") no-repeat center center fixed;
`;

export const MindfulMiddle = styled.div`
  height: inherit;
  min-height: 100vh;
  margin-right: auto;
  margin-left: auto;
  max-width: 700px;
  width: 75vw;
  background: white;
  padding: 139px 75px 75px 40px;
  border-right: 1px solid #e4e4e4;
  border-left: 1px solid #e4e4e4;
  box-sizing: border-box;
  
  &.dark-mode {
    background-color: #121212;
    color: #8A8A8A;
  }
`;

export const MindfulHeader = styled.div`
  text-align: center;
  color: #337ab7;
  font-size: 35px;
  height: 50px;
  margin-bottom: 25px;

  @media (max-width: 464px) {
    margin-bottom: 10vh;
  }
`;

export const MindfulnessSearchBar = styled.div`
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  border-top: 2px solid lightgrey;
  border-bottom: 2px solid lightgrey;
  height: 50px;
  max-width: 700px;
  width: 100%;
  margin-left: auto; 
  margin-right: auto;

  .menu {
    display: none;
    border: none;
    background-color: transparent;
    color: black;
    cursor: pointer;
    font-size: 16px;
    padding: 10px 15px;
    border-radius: 5px;
    border: 1px solid lightgrey; 
    outline: none;
  }

  .menu:focus {
    border-color: #337ab7;
  }

  @media (max-width: 768px) {
    border-top: 0px;
    border-bottom: 0px;
    .menu {
      display: block;
    }
  }
  
`;

export const MindButton = styled.button`
  color: black;
  background: transparent;
  cursor: pointer;
    border: none;

  &.dark-mode {
    color: #8A8A8A;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

import styled from "styled-components";

export const SettingsModalStyle = styled.div`
    @keyframes slideInFromRight {
        from {
            transform: translateX(100%);
        }
        to {
            transform: translateX(0);
        }
    }
    
    z-index: 1000;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: auto;
    width: 270px;
    height: 100%;
    background-color: white;
    animation: slideInFromRight 0.25s ease-out forwards;
    padding-left: 1%;
    padding-right: 1%;
    
    h2 {
        font-size: 22px;
        color: #8340dc;
        margin-bottom: 15px;
        margin-left: 10px;
    }

  &.dark-mode {
    background-color: #212121;
  }
`;

export const Goal = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: 10px;
    
    p {
        color: black;
        margin-bottom: 15px;
    }
    
    img {
        cursor: pointer;
    }

  &.dark-mode p{
    color: #8A8A8A;
  }
  
`;

export const SettingsBody = styled.div`
    font-size: 16px;
    color: #666;
    line-height: 1.5;
`;

export const SettingsCloseBtn = styled.button`
    cursor: pointer;
    font-size: 24px;
    color: #8340dc;
    position: absolute;
    top: 15px;
    right: 25px;
    background: none;
    border: none;
`;

export const SettingsItem = styled.h3`
    cursor: pointer;
    margin-left: 10px;
    
    :hover {
        color: #55346a;
    }
`;

export const SettingsButton = styled.button`
    background-color: #457AB2;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    display: block; /* Ensure buttons are block level for spacing */
    width: fit-content; /* Fit to content size */
    margin: 15px auto 0; /* Center button */
    
    :hover {
        background-color: #365f8c; /* Darker shade for hover state */
    }
`;

export const SettingsWheel = styled.button`
    svg {
        width: 35px;
        height: 35px;
    }
    width: 50px;
    padding-left: 20px;
    margin-right: 20px;
    color: #8340dc;
    border: none;

    &.dark-mode {
        color: #8340dc;
    }
`;

export const SettingsOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
`;

export const TaskList = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 200px;
    overflow-y: auto;
    margin-left: 10px;
    
    li {
        height: 70px;
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: left;
    }
    
    p {
        width: 50%;
        margin-left: 20px;
    }
    
    b {
        margin-left: auto;
        
        img {
            width: 30px;
            height: 30px;
        }
    }
    
    img {
        width: 10px;
        height: 10px;
    }
    
    input {
        width: 80px;
        padding-left: 5px;
        margin-right: 5px;
        border: 1px solid lightgrey;
    }
`;

import {fetcher} from "./Fetcher";
import {mutator} from "./Mutator";
import {Routine} from "../types/Routine";

export function fetchRoutineList(uid: string) {
    return fetcher<Routine[]>(`/bed/${uid}`, "GET");
}

export function createRoutineEntry(body?: any | null) {
    return mutator("/bed", "POST", body);
}

export function updateRoutineEntry(body?: any | null) {
    return mutator("/bed", "PUT", body);
}

export function deleteRoutineEntry(body?: any | null) {
    return mutator("/bed", "DELETE", body);
}

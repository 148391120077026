import Swal from "sweetalert2";
import {useMutation, useQuery} from "@tanstack/react-query";
import {User} from "../types/User";
import {fetchUser, updateUser} from "./User";
import {queryClient} from "./QueryClient";
import {getUserID} from "./firebase";

let url = window.location.href;
let OAUTH = "",
  OAUTHSettings = "";

//authenticate the user with fitbit API
if (url.includes("localhost")) {
  OAUTH =
    "https://www.fitbit.com/oauth2/authorize?response_type=token&client_id=22BG2J&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Freport&scope=activity%20sleep&expires_in=31536000";
  OAUTHSettings =
    "https://www.fitbit.com/oauth2/authorize?response_type=token&client_id=22BG2J&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fsettings&scope=activity%20sleep&expires_in=31536000";
} else {
  OAUTH =
    "https://www.fitbit.com/oauth2/authorize?response_type=token&client_id=22BG2J&redirect_uri=https%3A%2F%2Fsleepwebapp.wpi.edu%2Freport&scope=activity%20sleep&expires_in=31536000";
  OAUTHSettings =
    "https://www.fitbit.com/oauth2/authorize?response_type=token&client_id=22BG2J&redirect_uri=https%3A%2F%2Fsleepwebapp.wpi.edu%2Fsettings&scope=activity%20sleep&expires_in=31536000";
}

let today = new Date();
let dates: string[] = [];
//get dates of the past week including current day
for (let i = 6; i >= 0; i--) {
  let previousDay = new Date(today);
  previousDay.setDate(previousDay.getDate() - i);
  let day = previousDay.getFullYear() + "-";
  if (previousDay.getMonth() + 1 < 10) day += "0";
  day += previousDay.getMonth() + 1 + "-";
  if (previousDay.getDate() < 10) day += "0";
  day += previousDay.getDate();
  dates.push(day);
}

if (url.includes("dashboard") && url.includes("#")) {
  //getting the access token from url
  let access_token = url.split("#")[1].split("=")[1].split("&")[0];
  // get the userid
  let userId = url.split("#")[1].split("=")[2].split("&")[0];

  let sleepXhr = new XMLHttpRequest();
  // dates need to be in YYYY-MM-DD format
  sleepXhr.open(
    "GET",
    "https://api.fitbit.com/1/user/" +
      userId +
      "/sleep/date/" +
      dates[0] +
      "/" +
      dates[6] +
      ".json"
  );
  sleepXhr.setRequestHeader("Authorization", "Bearer " + access_token);
  sleepXhr.onload = function () {
    if (sleepXhr.status === 200) {
      let logs = JSON.parse(sleepXhr.responseText).sleep;
      //each sleep log data, parse and store in database
      for (let i = 0; i < logs.length; i++) {
        //parse date and time, reformat date and time to allow translation to timestamp in database
        let start = logs[i].startTime
          .replace(/-/g, "/")
          .replace(/T/, " ")
          .substring(0, 19);
        let end = logs[i].endTime
          .replace(/-/g, "/")
          .replace(/T/, " ")
          .substring(0, 19);

        // let idPromise = getUserID();
        // let newString = "";
        // idPromise.then((uid) => {
        //   for (var i = 0; i < uid.length; i++) {
        //     if (isNaN(uid[i])) {
        //       let temp = uid.charCodeAt(i);
        //       newString += temp;
        //     } else if (uid[i] === "-") {
        //     } else {
        //       newString += uid[i];
        //     }
        //   }
          const data = JSON.stringify({
            start: start,
            end: end,
            userID: getUserID(),
          });
          fetch(process.env.REACT_APP_API_URL + "/sleep", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: data,
          });
        // });
      }
      window.history.pushState("object or string", "Report", "/report");
    }
  };
  sleepXhr.send();
  console.log(dates);

  for (let i = 0; i < dates.length; i++) {
    let currDate = dates[i];
    //get exercise activity for each 7 days of the week
    let exerciseXhr = new XMLHttpRequest();
    exerciseXhr.open(
      "GET",
      "https://api.fitbit.com/1/user/" +
        userId +
        "/activities/date/" +
        currDate +
        ".json"
    );
    exerciseXhr.setRequestHeader("Authorization", "Bearer " + access_token);
    exerciseXhr.onload = function () {
      if (exerciseXhr.status === 200) {
        let activities = JSON.parse(exerciseXhr.responseText).summary;
        // let idPromise = getUserID();
        // let newString = "";
        // idPromise.then((uid) => {
        //   // let exerciseLog_low = {
        //   //     timestamp: dates[i].replace(/-/g, "/") + " 00:00:00",
        //   //     intensity: 2,
        //   //     minutes: activities.lightlyActiveMinutes,
        //   //     uid: uid
        //   // };
        //   for (var i = 0; i < uid.length; i++) {
        //     if (isNaN(uid[i])) {
        //       let temp = uid.charCodeAt(i);
        //       newString += temp;
        //     } else if (uid[i] === "-") {
        //     } else {
        //       newString += uid[i];
        //     }
        //   }
          console.log(dates);
          console.log(currDate);
          let exerciseLog_med = {
            date: currDate.replace(/-/g, "/") + " 00:00:00",
            avg_intensity: 50,
            minutes: activities.fairlyActiveMinutes,
            userID: getUserID(),
          };
          let exerciseLog_high = {
            date: currDate.replace(/-/g, "/") + " 00:00:00",
            avg_intensity: 98,
            minutes: activities.veryActiveMinutes,
            userID: getUserID(),
          };
          // //store lightly active minutes as medium intensity
          // if (exerciseLog_low.minutes !== 0) {
          //     const data_low = JSON.stringify(exerciseLog_low);
          //     fetch('https://sleepwebapp.wpi.edu:5000/newFitbitExercise', {
          //         method: 'POST',
          //         headers: {
          //             'Accept': 'application/json',
          //             'Content-Type': 'application/json',
          //         },
          //         body: data_low
          //     })
          // }
          //store fairy active minutes as medium intensity
          if (exerciseLog_med.minutes !== 0) {
            const data_med = JSON.stringify(exerciseLog_med);
            fetch(process.env.REACT_APP_API_URL + "/exercise", {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: data_med,
            });
          }
          //store very active minutes as high intensity
          if (exerciseLog_high.minutes !== 0) {
            const data_high = JSON.stringify(exerciseLog_high);
            fetch(process.env.REACT_APP_API_URL + "/exercise", {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: data_high,
            });
          }
        // });
        window.history.pushState("object or string", "Report", "/report");
      }
    };
    exerciseXhr.send();
  }
}

function InfoPopUp() {
  const getUser = useQuery<User, Error>({
    queryKey: ["users"],
    queryFn: () => fetchUser(getUserID()),
    refetchInterval: false
  });

  const putUser = useMutation({
    mutationKey: ["user"],
    mutationFn: (body: any) => updateUser(body),
    onSuccess: () => {
      console.log("User update success!");
      return queryClient.invalidateQueries({queryKey: ["users"]})
    }
  })

  if (getUser.isError) {
    console.log("Error: ", getUser.error)
  }

  if (getUser.data?.data.fitbit && getUser.data?.data.popup2) {
    Swal.fire({
      //send popup
      title: "Fitbit Feature",
      text:
          "You currently have Fitbit connected, It will auto log your sleep and exercise information. " +
          "If you manually log sleep, it will take priority over Fitbit data and if you manually log exercise it will be added to what was retrieved from your Fitbit!",
      showCancelButton: true,
      confirmButtonText: "Do Not Show Again",
      confirmButtonColor: "#cb1634",
      cancelButtonColor: "#b9b9b9",
      icon: "warning",
    })
        .then((noShow) => {
          if (noShow.value) {
            const body = {
              id: getUserID(),
              popup2: false
            }

            putUser.mutate(body);
          }
        });
  } else if (getUser.data?.data.popup) {
    Swal.fire({
      //send popup
      title: "Fitbit Feature",
      text: "If you have a Fitbit, you can auto log exercise and sleep using your Fitbit data. Go to settings to set that up!",
      showCancelButton: true,
      confirmButtonText: "Do Not Show Again",
      confirmButtonColor: "#cb1634",
      cancelButtonColor: "#b9b9b9",
    })
        .then((noShow) => {
          if (noShow.value) {
            const body = {
              id: getUserID(),
              popup: false
            }

            putUser.mutate(body);
          }
        });
  }

  //check if user set allow fitbit in User Settings
  // let idPromise = getUserID();
  // let newString = "";

  // idPromise.then((uid) => {
  //   for (var i = 0; i < uid.length; i++) {
  //     if (isNaN(uid[i])) {
  //       let temp = uid.charCodeAt(i);
  //       newString += temp;
  //     } else if (uid[i] === "-") {
  //     } else {
  //       newString += uid[i];
  //     }
  //   }
  //   const data = JSON.stringify({ id: newString });
  //   fetch(process.env.REACT_APP_API_URL + `/users/${newString}`, {
  //     method: "GET",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     body: data,
  //   })
  //     .then((r) => {
  //       return r.json();
  //     })
      // .then((r) => {
      //   if (r.length !== 0) {
      //     if (r[0].fitbit) {
      //       // fitbit use is true, warn users fitbit will auto fill sleep and exercise
      //       //check if user has set pop up to never show again
      //       fetch(process.env.REACT_APP_API_URL + `/users/${uid}`, {
      //         method: "GET",
      //         headers: {
      //           Accept: "application/json",
      //           "Content-Type": "application/json",
      //         },
      //         body: data,
      //       })
      //         .then((r) => {
      //           return r.json();
      //         })
      //         .then((r) => {
      //           if (r.length !== 0) {
      //             if (r[0].popup2) {
      //               //if popup is true send popup about fitbit in use. else do nothing
      //               swal
      //                 .fire({
      //                   //send popup
      //                   title: "Fitbit Feature",
      //                   text:
      //                     "You currently have Fitbit connected, It will auto log your sleep and exercise information. " +
      //                     "If you manually log sleep, it will take priority over Fitbit data and if you manually log exercise it will be added to what was retrieved from your Fitbit!",
      //                   showCancelButton: true,
      //                   confirmButtonText: "Do Not Show Again",
      //                   confirmButtonColor: "#cb1634",
      //                   cancelButtonColor: "#b9b9b9",
      //                   icon: "warning",
      //                 })
      //                 .then((noShow) => {
      //                   if (noShow.value) {
      //                     fetch(
      //                         process.env.REACT_APP_API_URL + "/users",
      //                       {
      //                         method: "PUT",
      //                         headers: {
      //                           Accept: "application/json",
      //                           "Content-Type": "application/json",
      //                         },
      //                         body: data,
      //                       }
      //                     );
      //                   }
      //                 });
      //             }
      //           }
      //         });
      //     } else {
            //fitbit is false, warn user they can connect fitbit to auto fill data
            //check if user has set pop up to never show again
            // fetch("https://sleepwebapp.wpi.edu:5000/getPopup", {
            //   method: "POST",
            //   headers: {
            //     Accept: "application/json",
            //     "Content-Type": "application/json",
            //   },
            //   body: data,
            // })
            //   .then((r) => {
            //     return r.json();
            //   })
  //             .then((r) => {
  //               if (r.length !== 0) {
  //                 if (r[0].popup) {
  //                   //if popup is true send popup about allowing fitbit. else do nothing
  //                   swal
  //                     .fire({
  //                       //send popup
  //                       title: "Fitbit Feature",
  //                       text: "If you have a Fitbit, you can auto log exercise and sleep using your Fitbit data. Go to settings to set that up!",
  //                       showCancelButton: true,
  //                       confirmButtonText: "Do Not Show Again",
  //                       confirmButtonColor: "#cb1634",
  //                       cancelButtonColor: "#b9b9b9",
  //                     })
  //                     .then((noShow) => {
  //                       if (noShow.value) {
  //                         fetch(
  //                           "https://sleepwebapp.wpi.edu:5000/setPopupFalse",
  //                           {
  //                             method: "POST",
  //                             headers: {
  //                               Accept: "application/json",
  //                               "Content-Type": "application/json",
  //                             },
  //                             body: data,
  //                           }
  //                         );
  //                       }
  //                     });
  //                 }
  //               }
  //             });
  //         }
  //       }
  //     });
  // });
}

export { OAUTH, OAUTHSettings, InfoPopUp };

import {fetcher} from "./Fetcher";
import {mutator} from "./Mutator";
import {Chronotype} from "../types/Chronotype";

export function fetchChronotype(uid: string) {
    return fetcher<Chronotype>(`/chronotype/${uid}`, "GET");
}

export function createChronotype(body: any | null) {
    return mutator("/chronotype", "POST", body);
}

export function updateChronotype(body: any | null) {
    return mutator("/chronotype", "PUT", body);
}

import {fetcher} from "./Fetcher";
import {mutator} from "./Mutator";
import {Stress} from "../types/Stress";

export function fetchStressList(uid: string) {
    return fetcher<Stress[]>(`/stress/${uid}`, "GET");
}

export function createStressEntry(body?: any | null) {
    return mutator("/stress", "POST", body);
}

export function updateStressEntry(body?: any | null) {
    return mutator("/stress", "PUT", body);
}

export function deleteStressEntry(body?: any | null) {
    return mutator("/stress", "DELETE", body);
}
import { Bar } from "react-chartjs-2";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import { Sleep } from "../types/Sleep";
import { fetchSleepList } from "../services/Sleep";
import { User } from "../types/User";
import { fetchUser } from "../services/User";
import {ChartOptions} from "chart.js";
import {getUserID} from "../services/firebase";

const isMobile = window.matchMedia("(max-width: 768px)").matches;
let labelSize = '10vw';

export default function SleepGraph() {

    const getSleep = useQuery<Sleep[], Error>({
        queryKey: ["sleep"],
        queryFn: () => fetchSleepList(getUserID())
    });

    let sleepTimeData, sleepDateData;

    if (getSleep.data) {
        const currDate = new Date();
        const compare = new Date(currDate.getFullYear(), currDate.getMonth(), currDate.getDate() - 7);
        const recentSleepData = getSleep.data.slice(0, 7).reverse().filter((a) => {
            return new Date(a.data.end_sleep) > compare;
        });

        sleepTimeData = recentSleepData.map(sleep => (new Date(sleep.data.end_sleep).getTime() - new Date(sleep.data.start_sleep).getTime()) / (1000 * 60 * 60));
        sleepDateData = recentSleepData.map(sleep => {
            const parsedDate = new Date(sleep.data.end_sleep);
            return `${parsedDate.getMonth() + 1}/${parsedDate.getDate()}`;
        });
    }

    const getUser = useQuery<User, Error>({
        queryKey: ["user"],
        queryFn: () => fetchUser(getUserID())
    });

    let sleepGoal = 0;
    if(getUser.data?.data.sleepGoal) {
        sleepGoal = getUser.data?.data.sleepGoal;
    }

    const arrayOfGoals = Array(8).fill(sleepGoal);

    const options: ChartOptions<'bar'> = {
        plugins: {
            legend: {
                labels: {
                    font: {
                        size: (context) => {
                            return isMobile ? 12 : 16;
                        }
                    }
                }
            }
        },
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false,
                },
                ticks: {
                    color: getUser.data?.data.darkMode ? '#8a8a8a' : 'rgba(0,0,0,1)',
                    font: {
                        size: parseInt(labelSize)
                    }
                }
            },
            y: {
                beginAtZero: true,
                ticks: {
                    color: getUser.data?.data.darkMode ? '#8a8a8a' : 'rgba(0,0,0,1)',
                    font: {
                        size: parseInt(labelSize)
                    }
                },
                grid: {
                    display: false,
                }
            },
        },
    };


    const data = {
        labels: sleepDateData,
        datasets: [
            {
                label: "Sleep (hrs)",
                borderSkipped: false,
                backgroundColor: "rgb(112, 57, 213)",
                borderWidth: 0,
                borderRadius: 0.012 * window.innerWidth,
                data: sleepTimeData,
            },
            {
                label: 'Goal',
                data: arrayOfGoals,
                borderSkipped: false,
                backgroundColor: 'white',
                borderRadius: 0.012 * window.innerWidth,
            }
        ],
    };

    return (
        <>
            <Bar options={options} data={data} />
        </>
    );
}

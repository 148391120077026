import React from "react";
import {TrackerButton, TrackerContent, TrackerMaster} from "./PersonalityTracker.styles";
import {useQuery} from "@tanstack/react-query";
import {User} from "../../types/User";
import {fetchUser} from "../../services/User";
import {getUserID} from "../../services/firebase";

export default function PersonalityTracker() {
    const getUser = useQuery<User, Error>({
        queryKey: ["user"],
        queryFn: () => fetchUser(getUserID())
    });

    return (
        <TrackerMaster className={`${getUser.data?.data.darkMode ? 'dark-mode' : ''}`}>
            <div id={"firebaseui-auth-container"} style={{display: "none"}}/>
            <TrackerContent className={`${getUser.data?.data.darkMode ? 'dark-mode' : ''}`}>
                <h1>Personality Tracker</h1>
                <h3>The Big Five</h3>
                <p>
                    The Big Five encompasses five characteristics: openness,
                    conscientiousness, extraversion, agreeableness, and neuroticism.
                </p>
                <p>
                    It will tell you how open, conscientious,agreeable, extroverted,
                    and neurotic you are. This is a quick, free quiz and when you are done
                    taking the quiz, report your scores for the Big Five Personality
                    Test.
                </p>
                <a href={"/personResults"}>
                    <TrackerButton imageUrl={"/images/personality3.jpg"}>
                        <p>Personality Results</p>
                    </TrackerButton>
                </a>

                <br/>

                <h1>Chronotype</h1>
                <h3>What is "Chronotype" and what is it used for?</h3>
                <p>
                    Chronotype tells you whether you are a morning or evening type.
                    This helps us notify you when it is most convenient!
                </p>
                <a href={"/chronoResults"}>
                    <TrackerButton imageUrl={"/images/alarm-clock.jpg"}>
                        <p>Chronotype Results</p>
                    </TrackerButton>
                </a>
            </TrackerContent>
        </TrackerMaster>
    );
}

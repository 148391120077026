import styled from "styled-components";
import {device} from "../../util/device";

export const RoutineMaster = styled.div`
    background: linear-gradient(180deg, #1f9ce490 0%, #625ad890 40%, #7339ab90 100%),
        url("/images/bedtime.jpg") no-repeat center center fixed;
    background-size: cover;
    height: inherit;
    width: 100vw;
    overflow: auto;
    display: table;
    z-index: 1;
`;

export const BedtimeContent = styled.div`
    min-height: 100vh;
    height: auto;
    background: white;
    margin-left: auto;
    margin-right: auto;
    max-width: 700px;
    width: 100%;
    box-sizing: border-box;
    padding: 75px 75px 40px;
    border-right: 1px solid #e4e4e4;
    border-left: 1px solid #e4e4e4;
    
    p {
        color: #337ab7;
        @media only screen and ${device.mobileM} {
            font-size: 4vw;
        }

        @media only screen and ${device.tablet} {
            font-size: 2vw;
        }
    }
  
  &.dark-mode {
    background-color: #121212;
    color: #8a8a8a;
  }
`;

export const RoutineButton = styled.button`
    background: #337ab7;
    border: 2px solid #337ab7;
    box-shadow: 5px 7px #00000030;
    border-radius: 100px;
    color: #ffffff;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
    padding: 13px 22px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    transition: all 0.4s ease-in-out;
    display: block;
    cursor: pointer;

    &:hover {
        background: #ffffff;
        border: 2px solid #337ab7;
        color: #337ab7;
    }
`;

export const RoutineTimer = styled.div`
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    padding-top: 15%;
    padding-bottom: 15%;
    border-bottom: black double;
    
    svg {
        width: 75%;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
    
    .CircularProgressbar-path {
        stroke: mediumpurple;
    }
`;

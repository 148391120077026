import React from "react";
import {useQuery} from "@tanstack/react-query";
import {Personality} from "../../types/Personality";
import {fetchPersonality} from "../../services/Personality";
import {TestButton, TrackerContent, TrackerMaster} from "./PersonalityTracker.styles";
import {getUserID} from "../../services/firebase";

export default function PersonalityResults() {
    const getPersonality = useQuery<Personality, Error>({
        queryKey: ["personality"],
        queryFn: () => fetchPersonality(getUserID()),
        refetchInterval: false
    });

    const dateView = (date: Date) => {
        const value = new Date(date);

        return (
            value.toDateString()
        );
    }

    const takeQuiz = () => {
        window.open(
            "https://www.truity.com/test/big-five-personality-test",
            "_blank",
            "width=1000, height=600"
        );

        window.location.replace(window.location.origin + "/personQuiz");
    }

    return (
        <TrackerMaster>
            <div id={"firebaseui-auth-container"} style={{display: "none"}}/>
            <TrackerContent>
                <h1>Your Big 5 Personality Results</h1>
                {getPersonality.data ?
                    (
                        <>
                            <h3>Results were last submitted on <>{dateView(getPersonality.data.data.date)}</></h3>

                            <h5>Openness</h5>
                            <p>Score: {getPersonality.data.data.openness}</p>

                            <h5>Conscientiousness</h5>
                            <p>Score: {getPersonality.data.data.conscientiousness}</p>

                            <h5>Extraversion</h5>
                            <p>Score: {getPersonality.data.data.extraversion}</p>

                            <h5>Agreeableness</h5>
                            <p>Score: {getPersonality.data.data.agreeableness}</p>

                            <h5>Neuroticism</h5>
                            <p>Score: {getPersonality.data.data.neuroticism}</p>

                            <TestButton onClick={() => takeQuiz()}>Retake Personality Test</TestButton>
                        </>
                    ) : (
                        <>
                            <h3>It looks like you haven't taken the test yet. Take the quiz below!</h3>
                            <TestButton onClick={() => takeQuiz()}>Take Personality Test</TestButton>
                        </>
                    )
                }
            </TrackerContent>
        </TrackerMaster>
    );
}

import styled from "styled-components";

//TODO make url a props
export const TrackerMaster = styled.div`
    background: linear-gradient(180deg, #1f9ce490 0%, #625ad890 40%, #7339ab90 100%),
        linear-gradient(180deg, #ffffffcc 0%, #ffffffcc 100%),
        url("/images/human.jpg") no-repeat center center fixed;
    background-size: cover;
    height: inherit;
    width: 100vw;
    overflow: auto;
    display: table;
    z-index: 1;
`;

export const TrackerContent = styled.div`
    min-height: 100vh;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    max-width: 700px;
    width: 100%;
    background: white;
    padding: 75px 75px 40px;
    border-right: 1px solid #e4e4e4;
    border-left: 1px solid #e4e4e4;
    box-sizing: border-box;
    
    h1 {
        text-align: center;
        color: #337ab7;
        border-bottom: double black medium;
        margin-top: 40px;
    }
    
    h3 {
        text-align: center;
        color: #337ab7;
    }
    
    h5 {
        color: #337ab7;
        display: block;
        margin-bottom: -5px;
        text-align: center;
    }
    
    p {
        font-weight: bold;
        font-size: 14px;
        text-align: center;
    }
    
    a {
        text-decoration: none;
    }

  &.dark-mode {
    background-color: #121212;
    color: #8a8a8a;
  }
`;

export const TrackerButton = styled.div<ImageProps>`
    background: url(${(props) => props.imageUrl}) no-repeat center;
    width: 100%;
    height: 100px;
    margin-top: 20px;
    cursor: pointer;
    background-size: cover;
    
    p {
        padding-top: 6%;
        font-size: 23px;
        color: white;
        text-shadow: 2px 2px 0 black;
        font-weight: bold;
        text-align: center;
    }
`;

export const TestButton = styled.button`
    background: #337ab7;
    border: 2px solid #337ab7;
    box-shadow: 5px 7px #00000030;
    border-radius: 100px;
    color: #ffffff;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
    padding: 13px 22px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    transition: all 0.4s ease-in-out;
    display: block;
    cursor: pointer;

    &:hover {
        background: #ffffff;
        border: 2px solid #337ab7;
        color: #337ab7;
    }
`;

export const InputFields = styled.div`
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
    padding-top: 15px;
    height: 40px;
    border: #1f9ce4 dotted;
`;

interface ImageProps {
    imageUrl: string;
}

import React, { useState } from 'react';
import {Bar, NavBarLeft, NavBarMiddle, NavBarRight} from "./navbar.styles";
import SettingsMenu from "../SettingsModal/settingsModal";
import {useQuery} from "@tanstack/react-query";
import {User} from "../../types/User";
import {fetchUser} from "../../services/User";
import DarkModeSwitch from "../DarkMode/DarkModeSwitch";
import { GiHamburgerMenu } from "react-icons/gi";
import NavigationMenu from "./navigationMenu";
import {getUserID} from "../../services/firebase";

export default function Navbar(){
    const getUser = useQuery<User, Error>({
        queryKey: ["user"],
        queryFn: () => fetchUser(getUserID())
    });

    return (
        <Bar className={`${getUser.data?.data.darkMode ? 'dark-mode' : ''}`}>
            <NavBarLeft>
                <div>
                    <a className="logo" href="/dashboard">
                        <img src="/images/Z3Logo.png" alt="Logo" />
                    </a>
                </div>
                <NavigationMenu/>
            </NavBarLeft>
            <NavBarMiddle>
                <span className="username">{getUser.data ? getUser.data.data.firstName : ""}</span>
            </NavBarMiddle>
            <NavBarRight className={`${getUser.data?.data.darkMode ? 'dark-mode' : ''}`}>
                <ul>
                    <li><a href="/bedtimeRoutine">Bedtime Routines</a></li>
                    <li><a href="/mindfulnessModules">Mindfulness</a></li>
                    <li><a href="/personalityOverview">Personality & Chronotype</a></li>
                    <SettingsMenu/>
                </ul>
            </NavBarRight>
        </Bar>
    );
}
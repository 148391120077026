import { Bar } from "react-chartjs-2";
import {DaysButton, GraphContainer} from "../pages/Dashboard/Dashboard.styles";
import {ScriptableContext} from "chart.js";
import React, {useState} from "react";
import StressPopUp from "./stressModal";
import { useQuery } from "@tanstack/react-query";
import {Stress} from "../types/Stress";
import {fetchStressList} from "../services/Stress";
import {User} from "../types/User";
import {fetchUser} from "../services/User";
import {getUserID} from "../services/firebase";


const isMobile = window.matchMedia("(max-width: 768px)").matches;
let labelSize = '10vw';

export default function StressGraph() {
    const [selectedDuration, setSelectedDuration] = useState(7);
    const [isStressModalOpen, setIsStressModalOpen] = useState(false);

    const closeStressModal = () => {
        setIsStressModalOpen(false);
    };

    const getUser = useQuery<User, Error>({
        queryKey: ["user"],
        queryFn: () => fetchUser("1234567890")
    });

    const getStress = useQuery<Stress[], Error>({
        queryKey: ["stress"],
        queryFn: () => fetchStressList(getUserID())
    });

    let stressDateData, stressLvlData;

    if (getStress.data) {
        const currDate = new Date();
        const compare = new Date(currDate.getFullYear(), currDate.getMonth(), currDate.getDate() - selectedDuration);
        const recentStressData = getStress.data.slice(0, selectedDuration).reverse().filter((a) => {
            return new Date(a.data.date) > compare;
        });

        stressLvlData = recentStressData.map(stress => stress.data.stress);
        stressDateData = recentStressData.map(stress => {const parsedDate = new Date(stress.data.date);
            return `${parsedDate.getMonth() + 1}/${parsedDate.getDate()}`;
        });
    }

    const options = {
        plugins: {
            legend: {
                onClick: () => {},
                labels: {
                    font: {
                        size: () => {
                            return isMobile ? 12 : 16; // Adjust font size based on device
                        }
                    }
                }
            }
        },
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false,
                },
                ticks: {
                    color: getUser.data?.data.darkMode ? '#8a8a8a' : 'rgba(0,0,0,1)',
                    font: {
                        size: parseInt(labelSize) // Parse the labelSize string into a number
                    }
                }
            },
            y: {
                ticks: {
                    beginAtZero: true,
                    color: getUser.data?.data.darkMode ? '#8a8a8a' : 'rgba(0,0,0,1)',
                    font: {
                        size: parseInt(labelSize) // Parse the labelSize string into a number
                    }
                },
                grid: {
                    display: false,
                }
            },
        },
    };

    const data = {
        labels: stressDateData,
        datasets: [
            {
                borderSkipped: false,
                label: "Stress Level",
                backgroundColor: (context: ScriptableContext<"line">) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 0, 0, 300);
                    const c1 = "rgb(173, 216, 230)";
                    const c2 = "rgb(87,148,190)";
                    gradient.addColorStop(0, c1);
                    gradient.addColorStop(1, c2);
                    return gradient;
                },
                borderWidth: 0,
                borderRadius: 0.012*window.innerWidth,
                data: stressLvlData,
            },
            // {
            //     label: 'Goal',
            //     borderSkipped: false,
            //     data:[20, 20, 20, 20, 20],
            //     backgroundColor: 'white',
            //     borderRadius: 0.012*window.innerWidth,
            // }
        ],

    };

    return (
        <GraphContainer>
            <div style={{
                display: "flex",
                justifyContent: "end"
            }}>
                <DaysButton onClick={() => setSelectedDuration(7)} className={selectedDuration === 7 ? "active" : ""}>7 Days</DaysButton>
                <DaysButton onClick={() => setSelectedDuration(30)} className={selectedDuration === 30 ? "active" : ""}>30 Days</DaysButton>
            </div>
            {/*@ts-ignore*/}
            <Bar options={options} data={data}></Bar>
            <StressPopUp isOpen={isStressModalOpen} onClose={closeStressModal} />
        </GraphContainer>
    );
}


import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Landing from "./pages/Landing/Landing";
import { Dashboard } from './pages/Dashboard/Dashboard';
import PersonalityQuiz from './pages/Personality/PersonalityQuiz';
import PersonalityResults from './pages/Personality/PersonalityResults';
import NotFound from './pages/NotFound/NotFound';
import BedtimeRoutine from "./pages/BedtimeRoutine/BedtimeRoutine";
import Mindfulness from "./pages/Mindfulness/Mindfulness";
import {QueryClientProvider} from "@tanstack/react-query";
import { queryClient } from "./services/QueryClient";
import Navbar from "./components/NavBar/navbar";
import PersonalityTracker from "./pages/Personality/PersonalityTracker";
import ChronotypeQuiz from "./pages/Personality/ChronotypeQuiz";
import ChronotypeResults from "./pages/Personality/ChronotypeResults";

export default function App() {
    return (
        <QueryClientProvider client={queryClient}>
            {window.location.pathname !== '/' ? <Navbar/> : null}
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Landing />} />
                    <Route path="/bedtimeRoutine" element={<BedtimeRoutine />} />
                    <Route path="/home" element={<Dashboard />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/chronoQuiz" element={<ChronotypeQuiz />} />
                    <Route path="/chronoResults" element={<ChronotypeResults />} />
                    <Route path="/personQuiz" element={<PersonalityQuiz />} />
                    <Route path="/personResults" element={<PersonalityResults />} />
                    <Route path="/mindfulnessModules" element={<Mindfulness />} />
                    <Route path="/personalityOverview" element={<PersonalityTracker />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </BrowserRouter>
        </QueryClientProvider>
    );
}

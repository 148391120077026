import styled from "styled-components";
import {device} from "../../../util/device";

export const AvgDiv = styled.div`
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 2.5vw;
  border: 1px solid #ccc;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

  &.dark-mode {
    background-color: #212121;
      color: white;
  }

    @media only screen and ${device.mobileM} {
        width: 90vw;
        margin-left: auto;
        margin-right: auto;
    }

    @media only screen and ${device.tablet} {
        width: 35vw;
        margin-left: 0;
        margin-right: 0;
    }
`;

export const AvgBox = styled.div`
    text-align: center;
    width: 50%;
    margin-bottom: 20px;
    
    p {
        margin: 0;
        font-weight: bold;
        color: #55346a;
    }

    @media only screen and ${device.mobileM} {
        font-size: 4vw;
    }

    @media only screen and ${device.tablet} {
        font-size: 1.25vw;
    }
`;

export const AvgList = styled.div`
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-right: auto;
    margin-left: auto;

    @media only screen and ${device.mobileM} {
        flex-direction: column;
        align-content: center;
    }

    @media only screen and ${device.tablet} {
        flex-direction: row;
    }
`;

export const AvgChoices = styled.div`
    display: flex;

    @media only screen and ${device.mobileM} {
        justify-content: center;
        margin: 0;
    }

    @media only screen and ${device.tablet} {
        justify-content: end;
        margin-top: 5px;
        margin-right: 5px;
    }
`;

import {fetcher} from "./Fetcher";
import {Personality} from "../types/Personality";
import {mutator} from "./Mutator";

export function fetchPersonality(uid: string) {
    return fetcher<Personality>(`/personality/${uid}`, "GET");
}

export function createPersonality(body: any | null) {
    return mutator("/personality", "POST", body);
}

export function updatePersonality(body: any | null) {
    return mutator("/personality", "PUT", body);
}

import React from 'react';
import { Line } from "react-chartjs-2";
import { ChartOptions} from "chart.js";
import { useQuery } from "@tanstack/react-query";
import {Sleep} from "../types/Sleep";
import {fetchSleepList} from "../services/Sleep";
import {User} from "../types/User";
import {fetchUser} from "../services/User";
import {getUserID} from "../services/firebase";
import Chart from 'chart.js/auto';

//GET for the users sleep data
Chart.defaults.font.size = 0.01*window.innerWidth;
const isMobile = window.matchMedia("(max-width: 768px)").matches;
let labelSize = '10vw';

export default function MonthGraph() {
    const getSleep = useQuery<Sleep[], Error>({
        queryKey: ["sleep"],
        queryFn: () => fetchSleepList(getUserID())
    });

    let sleepTimeData, sleepDateData;

    if (getSleep.data) {
        const currDate = new Date();
        const compare = new Date(currDate.getFullYear(), currDate.getMonth() - 1, currDate.getDate())
        const recentSleepData = getSleep.data.slice(0, 30).reverse().filter((a) => {
            return new Date(a.data.end_sleep) > compare;
        });
        sleepTimeData = recentSleepData.map(sleep => (new Date(sleep.data.end_sleep).getTime() - new Date(sleep.data.start_sleep).getTime()) / (1000 * 60 * 60));
        sleepDateData = recentSleepData.map(sleep => {const parsedDate = new Date(sleep.data.end_sleep);
            return `${parsedDate.getMonth() + 1}/${parsedDate.getDate()}`;
        });
    }

    const getUser = useQuery<User, Error>({
        queryKey: ["user"],
        queryFn: () => fetchUser(getUserID())
    });

    let sleepGoal = 0;
    if(getUser.data?.data.sleepGoal) {
        sleepGoal = getUser.data?.data.sleepGoal;
    }

    const arrayOfGoals = Array(30).fill(sleepGoal);

    const options: ChartOptions<'bar'> = {
        plugins: {
            legend: {
                labels: {
                    font: {
                        size: (context) => {
                            return isMobile ? 12 : 16;
                        }
                    }
                }
            }
        },
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false,
                },
                ticks: {
                    color: getUser.data?.data.darkMode ? '#8a8a8a' : 'rgba(0,0,0,1)',
                    font: {
                        size: parseInt(labelSize)
                    }
                }
            },
            y: {
                beginAtZero: true,
                ticks: {
                    color: getUser.data?.data.darkMode ? '#8a8a8a' : 'rgba(0,0,0,1)',
                    font: {
                        size: parseInt(labelSize)
                    }
                },
                grid: {
                    display: false,
                }
            },
        },
    };
    const data = {
        labels: sleepDateData,
        datasets: [
            {
                label: "Sleep (hrs)",
                data: sleepTimeData,
                borderColor: 'rgba(112,10,206,1)',
                backgroundColor: 'rgba(112,10,206,1)',
                borderWidth: 2,
                spanGaps: true,
            },
            {
                label: 'Goal',
                data: arrayOfGoals,
                borderColor: 'white',
                backgroundColor: 'white',
                borderWidth: 2,
                spanGaps: true,
            }
        ],

    };

    return (
        <div>
            {/*@ts-ignore*/}
            <Line options={options} data={data}></Line>
        </div>
    );
}


import {Tab} from "semantic-ui-react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Battery90Icon from "@mui/icons-material/Battery90";
import LocalCafe from "@mui/icons-material/LocalCafe";
import React, {useState} from "react";
import {styled} from "@mui/material/styles";
import Slider from "@mui/material/Slider";
import {createCaffeineEntry} from "../services/Caffeine";
import {queryClient} from "../services/QueryClient";
import {useMutation} from "@tanstack/react-query";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {getUserID} from "../services/firebase";
import {SliderButton} from "../pages/Dashboard/Dashboard.styles";

const PrettoSlider = styled(Slider)(({theme}) => ({
    color: 'mediumpurple',
    '& .MuiSlider-thumb': {
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        height: '3.25vh',
        width: '3.25vh',
    },
    '& .MuiSlider-track': {
        height: '2.5vh',
        width: '25vw',
        borderRadius: '2.5vh',
    },
    '& .MuiSlider-rail': {
        height: '2.5vh',
        width: '25vw',
        borderRadius: '2.5vh',
    },
}));

const CaffeineSlider = ({ onClose }) => {
    const [cupSizeValue, setCupSizeValue] = useState(12);
    const [cupsValue, setCupsValue] = useState(0);
    const [caffeineTime, setCaffeineTime] = useState(new Date()); // State to store the datetime value

    const handleCupSizeChange = (event, value) => {
        setCupSizeValue(value);
    };

    const handleCupsChange = (event, value) => {
        setCupsValue(value);
    };

    const createCaffeine = useMutation({
        mutationKey: ["caffeine"],
        mutationFn: (body: any) => createCaffeineEntry(body),
        onSuccess: () => {
            console.log("Successfully created caffeine entry!");
            return queryClient.invalidateQueries({queryKey: ["caffeine"]});
        }
    });

    const cupSize = [
        {value: 6, label: "6 oz",},
        {value: 12, label: "12 oz",},
        {value: 18, label: "18 oz",},
    ];

    // Function to handle changes in the datetime input field
    const handleCaffeineTimeChange = (date: Date) => {
        setCaffeineTime(date);
    };

    const handleEnterCaffeine = async () => {
        const correctedDate = `${caffeineTime.getFullYear()}-${caffeineTime.getMonth()+1}-${caffeineTime.getDate()}T00:00:00.000Z`;

        const body = {
            userID: getUserID(),
            cups: cupsValue,
            date: correctedDate, // Use the captured datetime value
            avg_size: cupSizeValue,
        };

        try {
            await createCaffeine.mutateAsync(body);
            onClose();
        } catch (error) {
            console.error("Error creating sleep entry:", error);
            // Handle error
        }
    };

    return (
        <>
            <div style={{
                display: "flex",
                justifyContent: "center"
            }}>
                <Tab.Pane attached={false} style={{fontSize: '2vh'}}>
                    <h5 style={{fontSize: '2.75vh'}}>Log the amount of caffeine consumed</h5>
                    <Typography id="discrete-slider-restrict" gutterBottom style={{fontSize: '2.5vh'}}>
                        Average Beverage Size
                    </Typography>
                    <Grid container spacing={'1vw'}>
                        <Grid item>
                            <Battery90Icon style={{height: '5vh', width: '5vh'}}/>
                        </Grid>

                        <Grid item xs style={{width: '25vw'}}>
                            <PrettoSlider
                                id="cupSize"
                                aria-label="pretto slider"
                                defaultValue={12}
                                onChange={handleCupSizeChange}
                                step={6}
                                min={6}
                                max={18}
                                marks={cupSize}
                            />
                        </Grid>
                    </Grid>
                    <br/>
                    <Typography id="discrete-slider-restrict" gutterBottom style={{fontSize: '2.5vh'}}>
                        Cups of Caffeinated Beverages
                    </Typography>
                    <Grid container spacing={'1vw'}>
                        <Grid item>
                            <LocalCafe style={{height: '5vh', width: '5vh'}}/>
                        </Grid>

                        <Grid item xs style={{width: '25vw'}}>
                            <PrettoSlider
                                id="cups"
                                aria-label="pretto slider"
                                defaultValue={0}
                                onChange={handleCupsChange}
                                valueLabelDisplay="auto"
                                step={1}
                                min={0}
                                max={5}
                                marks={[
                                    {value: 0, label: 0},
                                    {value: 1, label: 1},
                                    {value: 2, label: 2},
                                    {value: 3, label: 3},
                                    {value: 4, label: 4},
                                    {value: 5, label: 5},
                                ]}
                            />
                        </Grid>
                    </Grid>
                    Click the date to change it!
                    <DatePicker selected={caffeineTime} onChange={handleCaffeineTimeChange}/>
                </Tab.Pane>
            </div>
            <SliderButton onClick={handleEnterCaffeine}>
                Enter Caffeine
            </SliderButton>
        </>
    );
};
export default CaffeineSlider;

import {fetcher} from "./Fetcher";
import {mutator} from "./Mutator";
import {User} from "../types/User";

export function fetchUser(id: string) {
    return fetcher<User>(`/users/${id}`, "GET");
}

export function createUser(body?: any | null) {
    return mutator("/users", "POST", body);
}

export function updateUser(body?: any | null) {
    return mutator("/users", "PUT", body);
}

export function deleteUserID(body?: any | null) {
    return mutator("/users", "DELETE", body);
}

import styled from "styled-components";
import {device} from "../../../util/device";

export const CalendarDiv = styled.div`
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 2.5vw;
    border: 1px solid #ccc;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

    .bubble {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #671bca;
        color: white;
        padding: 6px 16px;
        border-radius: 20px;
        font-weight: bold;
        cursor: pointer;
        font-size: 10px;
        text-align: center;
        transition: background-color 0.3s ease-in-out;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }

    .bubble:hover {
        background-color: #4a0c9c;
    }

  .heading {
    font-size: 18px;
    font-weight: bold;
    color: #55346a;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    overflow: hidden;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 14px;
      gap: 4px;
    }

    @media (max-width: 500px) {
      font-size: 14px;
    }
  }

  .date-switch-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 26px;
    font-size: 20px;
    position: relative;
  }

  .arrows {
    cursor: pointer;
    font-size: 16px;
  }

  .events-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    max-height: 80%;
    overflow-y: auto;
  }

  .event {
    display: flex;
    background-color: #8340dc;
    padding: 20px 14px;
    border-radius: 10px;
    color: white;
    border-left: 6px solid #671bca;
    gap: 10px;

    @media (max-width: 1500px) {
      flex-direction: column;
      max-height: 140px;
    }
  }

  .stress-level {
    @media (max-width: 1024px) {
      font-size: 10px;
    }
  }

  da .title {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    color: white;
  }

  .title {
    font-weight: bold;
    text-overflow: ellipsis;
    overflow-y: hidden;
    font-size: 20px;

    @media (max-width: 1024px) {
      font-size: 14px;
      font-weight: bold;
    }

    @media (max-width: 500px) {
      font-size: 14px;
      font-weight: bold;
      white-space: nowrap;
    }
  }

  .date {
    font-size: 12px;
    margin-right: 10px;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  .info {
    font-size: 12px;
    display: flex;
    gap: 10px;
  }

  &.dark-mode {
    background-color: #212121;
  }

  &.dark-mode .event {
    background-color: #55346a;
  }

  &.dark-mode .heading {
    color: #8340dc;
  }

    @media only screen and ${device.mobileM} {
        width: 86vw;
        padding: 2%;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 15px;
    }

    @media only screen and ${device.tablet} {
        width: 52vw;
        padding: 16px 30px;
        margin: 0;
    }
`;

export const SyncButtons = styled.div`
    align-content: center;
    
    button {
        background-color: #457ab2;
        color: white;
        border-radius: 20px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        cursor: pointer;
        width: 100%;
        text-align: center;

        @media only screen and ${device.mobileM} {
            font-size: 4vw;
            padding: 2.5%;
            margin-top: 0;
        }

        @media only screen and ${device.tablet} {
            font-size: 1.2vw;
            margin-top: 2.4%;
            padding: 3%;
        }
    }
`;

import React from "react";
import "../../styles/awesome-bootstrap-checkbox-master/awesome-bootstrap-checkbox.css";
import "../../styles/ItsBedtime.css";

export default function MindfulEating() {
    return (
      <div>
        <br />
        <iframe
            width="550"
            style={{ maxWidth: '100%', aspectRatio: '16 / 9' }}
          src="https://www.youtube.com/embed/Emd9q6_o6Z0"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; 
                            encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
        <br/>
        <br />
        <p>
          <b>Video Description:</b> Editorial director of the Nutrition Source
          Website, Lillian Cheung, explains the seven practices of mindful
          eating and the best practices for living more mindfully.
        </p>
        <p>
<a href="https://www.health.harvard.edu/staying-healthy/8-steps-to-mindful-eating">
          Source
        </a>
        </p>
        <br />
        <hr className="hr-settings" />
        <br />
        <p>Spotify Mindful Eating Playlist</p>
        <br/>
        <a href="https://open.spotify.com/playlist/2NUOJcmQv9b0QBPY5vgZkO"> 
        <img src="https://media.gettyimages.com/id/1279763992/photo/healthy-food-for-lower-cholesterol-and-heart-care-shot-on-wooden-table.jpg?s=612x612&w=0&k=20&c=AciLLDoSmYjWZlCwmFiFu8L_wbOmY8ZBxy9y_pLaiGw="
        alt="Spotify Eating Playlist" width="550" height="400" style={{ maxWidth: '100%', height: 'auto' }}/></a>
      </div>
    );
}

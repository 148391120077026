import React, {useState} from "react";
import {InputFields, TestButton, TrackerContent, TrackerMaster} from "./PersonalityTracker.styles";
import {useMutation, useQuery} from "@tanstack/react-query";
import {createPersonality, fetchPersonality, updatePersonality} from "../../services/Personality";
import {Personality} from "../../types/Personality";
import {queryClient} from "../../services/QueryClient";
import {getUserID} from "../../services/firebase";

export default function PersonalityQuiz() {
    const [open, setOpen] = useState(0);
    const [con, setCon] = useState(0);
    const [extra, setExtra] = useState(0);
    const [agree, setAgree] = useState(0);
    const [neuro, setNeuro] = useState(0);

    const getPersonality = useQuery<Personality, Error>({
        queryKey: ["personality"],
        queryFn: () => fetchPersonality(getUserID()),
        refetchInterval: false
    });

    const newPersonality = useMutation({
        mutationKey: ["person"],
        mutationFn: (body: any) => createPersonality(body),
        onSuccess: () => {
            console.log("Successfully created personality!");
            queryClient.invalidateQueries({queryKey: ["personality"]});
            window.location.replace(window.location.origin + "/personResults");
        }
    });

    const changePersonality = useMutation({
        mutationKey: ["person"],
        mutationFn: (body: any) => updatePersonality(body),
        onSuccess: () => {
            console.log("Successfully created personality!");
            queryClient.invalidateQueries({queryKey: ["personality"]});
            window.location.replace(window.location.origin + "/personResults");
        }
    });

    const submit = () => {
        const body = {
            userID: getUserID(),
            openness: open,
            conscientiousness: con,
            extraversion: extra,
            agreeableness: agree,
            neuroticism: neuro,
            date: new Date()
        }

        if (getPersonality.data) {
            changePersonality.mutate(body);
        } else {
            newPersonality.mutate(body);
        }
    }

    return (
        <TrackerMaster>
            <div id={"firebaseui-auth-container"} style={{display: "none"}}/>
            <TrackerContent>
                <h1>Your Personality Scores</h1>
                <p>Use the results from the big 5 quiz to fill in your scores below.</p>
                <p>0% - 33% = low</p>
                <p>34% - 66% = medium</p>
                <p>67% - 100% = high</p>

                <h5>Openness</h5>
                <InputFields>
                    <div>
                        <input
                            type={"radio"}
                            name={"open"}
                            onClick={() => setOpen(1)}
                        />
                        <label>Low</label>
                    </div>

                    <div>
                        <input
                            type={"radio"}
                            name={"open"}
                            onClick={() => setOpen(5)}
                        />
                        <label>Medium</label>
                    </div>

                    <div>
                        <input
                            type={"radio"}
                            name={"open"}
                            onClick={() => setOpen(10)}
                        />
                        <label>High</label>
                    </div>
                </InputFields>

                <h5>Conscientiousness</h5>
                <InputFields>
                    <div>
                        <input
                            type={"radio"}
                            name={"con"}
                            onClick={() => setCon(1)}
                        />
                        <label>Low</label>
                    </div>

                    <div>
                        <input
                            type={"radio"}
                            name={"con"}
                            onClick={() => setCon(5)}
                        />
                        <label>Medium</label>
                    </div>

                    <div>
                        <input
                            type={"radio"}
                            name={"con"}
                            onClick={() => setCon(10)}
                        />
                        <label>High</label>
                    </div>
                </InputFields>

                <h5>Extraversion</h5>
                <InputFields>
                    <div>
                        <input
                            type={"radio"}
                            name={"extra"}
                            onClick={() => setExtra(1)}
                        />
                        <label>Low</label>
                    </div>

                    <div>
                        <input
                            type={"radio"}
                            name={"extra"}
                            onClick={() => setExtra(5)}
                        />
                        <label>Medium</label>
                    </div>

                    <div>
                        <input
                            type={"radio"}
                            name={"extra"}
                            onClick={() => setExtra(10)}
                        />
                        <label>High</label>
                    </div>
                </InputFields>

                <h5>Agreeableness</h5>
                <InputFields>
                    <div>
                        <input
                            type={"radio"}
                            name={"agree"}
                            onClick={() => setAgree(1)}
                        />
                        <label>Low</label>
                    </div>

                    <div>
                        <input
                            type={"radio"}
                            name={"agree"}
                            onClick={() => setAgree(5)}
                        />
                        <label>Medium</label>
                    </div>

                    <div>
                        <input
                            type={"radio"}
                            name={"agree"}
                            onClick={() => setAgree(10)}
                        />
                        <label>High</label>
                    </div>
                </InputFields>

                <h5>Neuroticism</h5>
                <InputFields>
                    <div>
                        <input
                            type={"radio"}
                            name={"neuro"}
                            onClick={() => setNeuro(1)}
                        />
                        <label>Low</label>
                    </div>

                    <div>
                        <input
                            type={"radio"}
                            name={"neuro"}
                            onClick={() => setNeuro(5)}
                        />
                        <label>Medium</label>
                    </div>

                    <div>
                        <input
                            type={"radio"}
                            name={"neuro"}
                            onClick={() => setNeuro(10)}
                        />
                        <label>High</label>
                    </div>
                </InputFields>
                <TestButton onClick={() => submit()}>Submit Scores</TestButton>
            </TrackerContent>
        </TrackerMaster>
    )
        ;
}

import { getAuth } from "firebase/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
let firebaseui = require("firebaseui");

const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_API_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

export const auth = getAuth(app);

let ui = new firebaseui.auth.AuthUI(auth);
ui.start('#firebaseui-auth-container', {
  callbacks: {
    signInSuccessWithAuthResult: function () {
      return true;
    },
    uiShown: function () {
      if (document.getElementById("loader")) {
        document.getElementById("loader")!.style.display = "none";
      }
    },
  },
  signInFlow: "popup",
  signInSuccessUrl: "/home",
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    "microsoft.com"
  ],
});

export function logout() {
  firebase
      .auth()
      .signOut()
      .then(
          function () {
            window.location.replace(window.location.origin);
          },
          function (error) {
            console.error("Sign Out Error", error);
          }
      );
}

/*
 * For getting the id of the user depending on prod or dev/local
 */
export function getUserID() {
  if (process.env.REACT_APP_ENV === "prod") {
    return auth.currentUser ? auth.currentUser.uid : "";
  } else {
    return "1234567890"
  }
}

export default app;

import {fetcher} from "./Fetcher";
import {mutator} from "./Mutator";
import {Exercise} from "../types/Exercise";

export function fetchExerciseList(uid: string) {
    return fetcher<Exercise[]>(`/exercise/${uid}`, "GET");
}

export function createExerciseEntry(body?: any | null) {
    return mutator("/exercise", "POST", body);
}

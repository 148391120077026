// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;

}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;

}

.slider {
    position: absolute;
    cursor: pointer;
    border-radius: 15px;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: #ccc;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 12px;
}

input:checked + .slider {
    background-color: #457AB2;
}

input:checked + .slider:before {
    transform: translateX(26px);
}`, "",{"version":3,"sources":["webpack://./src/components/DarkMode/DarkModeSwitch.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,WAAW;IACX,YAAY;;AAEhB;;AAEA;IACI,UAAU;IACV,QAAQ;IACR,SAAS;;AAEb;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,mBAAmB;;IAEnB,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;;IAET,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,WAAW;IACX,SAAS;IACT,WAAW;IACX,uBAAuB;IACvB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,2BAA2B;AAC/B","sourcesContent":[".switch {\n    position: relative;\n    display: inline-block;\n    width: 60px;\n    height: 34px;\n\n}\n\n.switch input {\n    opacity: 0;\n    width: 0;\n    height: 0;\n\n}\n\n.slider {\n    position: absolute;\n    cursor: pointer;\n    border-radius: 15px;\n\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n\n    background-color: #ccc;\n}\n\n.slider:before {\n    position: absolute;\n    content: \"\";\n    height: 26px;\n    width: 26px;\n    left: 4px;\n    bottom: 4px;\n    background-color: white;\n    transition: 0.4s;\n    border-radius: 12px;\n}\n\ninput:checked + .slider {\n    background-color: #457AB2;\n}\n\ninput:checked + .slider:before {\n    transform: translateX(26px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

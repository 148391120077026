import {Modal} from "react-bootstrap";
import React, {useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {User} from "../../types/User";
import { fetchUser } from "../../services/User";
import {BurgerMenu, NavBody, NavCloseBtn, NavModalStyle, NavOverlay, NavItem} from "./navigationMenu.styles";
import {GiHamburgerMenu} from "react-icons/gi";
import {getUserID} from "../../services/firebase";

export default function NavigationMenu() {
    const [show, setShow] = useState(false);

    const getUser = useQuery<User, Error>({
        queryKey: ["user"],
        queryFn: () => fetchUser(getUserID())
    });

    return (
        <>
            <BurgerMenu className={`${getUser.data?.data.darkMode ? 'dark-mode' : ''}`} onClick={() => setShow(!show)}>
                <GiHamburgerMenu/>
            </BurgerMenu>
            <Modal show={show} onHide={() => setShow(!show)}>
                <NavOverlay/>
                <NavModalStyle className={`${getUser.data?.data.darkMode ? 'dark-mode' : ''}`}>
                    <NavCloseBtn onClick={() => setShow(!show)}>x</NavCloseBtn>
                    <h2>Navigation</h2>
                    <NavBody>
                        <NavItem><a href="/dashboard">Dashboard</a></NavItem>
                        <NavItem><a href="/bedtimeRoutine">Bedtime Routines</a></NavItem>
                        <NavItem><a href="/mindfulnessModules">Mindfulness</a></NavItem>
                        <NavItem><a href="/personalityOverview">Personality & Chronotype</a></NavItem>
                    </NavBody>
                </NavModalStyle>
            </Modal>
        </>
    );
}

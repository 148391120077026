import styled from "styled-components";

export const Bar = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: white;
  color: #000000;
  border-bottom: 1px solid #cccccc;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100vw;
  z-index: 1000;

  &.dark-mode {
    background: #121212;
    color: #8a8a8a;
    border-bottom: 1px solid #333;
    box-shadow: 0 5px 10px rgba(255, 255, 255, 0.1);
  }
`;

export const NavBarLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  img {
    margin-left: 10px;
    margin-right: 10px;
    width: 58px;
    height: 42px;
  }

  @media (max-width: 768px) {
    .logo {
      display: none;
    }
  }
`;

export const NavBarMiddle = styled.div`
  flex-grow: 1;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }
`;

export const NavBarRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  ul {
    list-style-type: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  li {
    margin-left: 40px;
  }

  a {
    color: black;
    text-decoration: none;

    @media (max-width: 1024px) {
      font-size: 16px;
    }
  }

  button {
    background-color: white;
    cursor: pointer;
  }

  &.dark-mode {
    background-color: #121212;
  }

  &.dark-mode a {
    color: #8a8a8a;
  }

  &.dark-mode button {
    background-color: #121212;
  }

  @media (max-width: 768px) {
    li {
      display: none;
    }
  }
`;

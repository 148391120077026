import styled from "styled-components";
import {device} from "../../../util/device";

export const StatDiv = styled.div`
    display: flex;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 2.5vw;
    border: 1px solid #ccc;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

    @media only screen and ${device.mobileM} {
        flex-direction: column;
        width: 90vw;
        margin-left: auto;
        margin-right: auto;
    }

    @media only screen and ${device.tablet} {
        flex-direction: row;
        width: 35vw;
        margin-left: 0;
        margin-right: 0;
    }

    &.dark-mode {
        background-color: #212121;
    }
`;

export const IconMenu = styled.div`
    display: flex;
    
    .icon {
        cursor: pointer;

        @media only screen and ${device.mobileM} {
            margin-bottom: 15px;
            height: 10vw;
            width: 10vw;
        }

        @media only screen and ${device.tablet} {
            margin-bottom: 100%;
            height: 1.5vw;
            width: 1.5vw;
        }
    }

    @media only screen and ${device.mobileM} {
        flex-direction: row;
        justify-content: space-evenly;
    }

    @media only screen and ${device.tablet} {
        flex-direction: column;
        justify-content: center;
    }
`;

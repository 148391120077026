import React, { useState } from 'react';
import Modal from 'react-modal';
import "react-datepicker/dist/react-datepicker.css";

Modal.setAppElement('#root');

const StressPopUp = ({ isOpen, onClose }) => {
    const [stressLevel, setStressLevel] = useState(0);

    const handleSliderChange = (event) => {
        setStressLevel(parseInt(event.target.value));
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <>
            <Modal
                isOpen={isOpen}
                onRequestClose={handleClose}
                contentLabel="Stress Popup"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: '1000',
                    },
                    content: {
                        overflow: 'hidden',
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '50%',
                        height: '50%',
                        borderRadius: 40,
                        minWidth: '700px',
                        minHeight: '450px',
                        animation: `popOut 0.5s ease-out forwards`,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        position: 'relative',
                    },
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: '15px',
                        right: '25px',
                        cursor: 'pointer',
                        zIndex: '1',
                        fontSize: '40px',
                        fontWeight: 'lighter',
                        color: 'gray',
                    }}
                    onClick={handleClose}
                >
                    x
                </div>
                <h2 style={{ marginBottom: '20px', textAlign: 'center' }}>Choose Your Stress Level</h2>
                <div style={{ width: '80%', marginBottom: '20px' }}>
                    <input
                        type="range"
                        id="stressLevel"
                        name="stressLevel"
                        min="0"
                        max="10"
                        value={stressLevel}
                        onChange={handleSliderChange}
                        style={{
                            width: '100%',
                            borderRadius: '10px',
                            background: 'linear-gradient(to right, #8A2BE2, #4169E1)',
                            border: 'none',
                            boxShadow: 'inset 0 1px 2px rgba(0, 0, 0, 0.1)',
                            cursor: 'pointer',
                        }}
                    />
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
                        <span>0</span>
                        <span>5</span>
                        <span>10</span>
                    </div>
                </div>
                <p style={{ fontSize: '20px', textAlign: 'center', marginBottom: '100px' }}>Stress Level: {stressLevel}</p>
                <button onClick={handleClose} style={{backgroundColor: '#457AB2',
                    color: 'white',
                    border: 'none',
                    borderRadius: '20px',
                    padding: '10px 20px',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                    position: 'absolute',
                    bottom: '40px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    cursor: 'pointer',
                    marginTop: '10px',}}>
                    Enter Stress lvl
                </button>
            </Modal>
        </>
    );
};

export default StressPopUp;

import {FaCoffee, FaDizzy, FaRunning, FaSmile, FaTint} from "react-icons/fa";
import React, {useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {User} from "../../../types/User";
import {fetchUser} from "../../../services/User";
import {getUserID} from "../../../services/firebase";
import MoodGraph from "../../../atoms/moodGraph";
import StressGraph from "../../../atoms/stressGraph";
import CaffeineGraph from "../../../atoms/caffeineGraph";
import WaterGraph from "../../../atoms/waterGraph";
import ExerciseGraph from "../../../atoms/exerciseGraph";
import {IconMenu, StatDiv} from "./StatsModule.styles";

export default function StatsModule() {
    const [selectedOption, setSelectedOption] = useState("stress");

    const getUser = useQuery<User, Error>({
        queryKey: ["user"],
        queryFn: () => fetchUser(getUserID())
    });

    const renderSelectedOption = (option: string) => {
        switch (option) {
            case "mood":
                return (
                    <MoodGraph />
                );
            case "stress":
                return (
                    <StressGraph />
                );
            case "caffeine":
                return (
                    <CaffeineGraph />
                );
            case "water":
                return (
                    <WaterGraph />
                );
            case "exercise":
                return (
                    <ExerciseGraph />
                );
            default:
                return null;
        }
    };

    return (
        <StatDiv className={`${getUser.data?.data.darkMode ? 'dark-mode' : ''}`}>
            <div style={{width: "94%"}}>{renderSelectedOption(selectedOption)}</div>
            <IconMenu>
                <FaDizzy
                    className={`icon ${selectedOption === 'stress' ? 'selected' : ''}`}
                    onClick={() => setSelectedOption('stress')}
                    style={{color: selectedOption === 'stress' ? 'black' : 'grey'}}
                />
                <FaCoffee
                    className={`icon ${selectedOption === 'caffeine' ? 'selected' : ''}`}
                    onClick={() => setSelectedOption('caffeine')}
                    style={{color: selectedOption === 'caffeine' ? 'black' : 'grey'}}
                />
                <FaSmile
                    className={`icon ${selectedOption === 'mood' ? 'selected' : ''}`}
                    onClick={() => setSelectedOption('mood')}
                    style={{color: selectedOption === 'mood' ? 'black' : 'grey'}}
                />
                <FaTint
                    className={`icon ${selectedOption === 'water' ? 'selected' : ''}`}
                    onClick={() => setSelectedOption('water')}
                    style={{color: selectedOption === 'water' ? 'black' : 'grey'}}
                />
                <FaRunning
                    className={`icon ${selectedOption === 'exercise' ? 'selected' : ''}`}
                    onClick={() => setSelectedOption('exercise')}
                    style={{color: selectedOption === 'exercise' ? 'black' : 'grey'}}
                />
            </IconMenu>
        </StatDiv>
    );
}

import React, {useState} from "react";
import StressCalendarLogger from "../../../atoms/stressCalendarLogger";
import {CalendarDiv, SyncButtons} from "./CalendarModule.styles";
import {useQuery} from "@tanstack/react-query";
import {User} from "../../../types/User";
import {fetchUser} from "../../../services/User";
import {getUserID} from "../../../services/firebase";
import {PublicClientApplication} from "@azure/msal-browser";
import ApiCalendar from "react-google-calendar-api";
import {Stress} from "../../../types/Stress";
import {fetchStressList} from "../../../services/Stress";
import DatePicker from 'react-datepicker';

const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_MSAL_CLIENT_ID!,
        authority: process.env.REACT_APP_MSAL_AUTHORITY!,
        clientSecret: process.env.REACT_APP_MSAL_CLIENT_SECRET!,
        redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI!,
    },
};

const googleConfig = {
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID!,
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY!,
    scope: process.env.REACT_APP_GOOGLE_SCOPE!,
    discoveryDocs: [process.env.REACT_APP_GOOGLE_DISCOVERY_DOCS!],
};

const cca = new PublicClientApplication(msalConfig);

const googleCalendar = new ApiCalendar(googleConfig);

const initializeMSAL = async () => {
    try {
        await cca.initialize();
    } catch (error) {
        console.error('MSAL initialization failed:', error);
        // Handle initialization error
    }
};

export default function CalendarModule() {
    const [syncedAccount, setSyncedAccount] = useState(false);
    const [calendarEvents, setCalendarEvents] = useState<any[]>([]);
    const [isEventOpen, setIsEventOpen] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [calendarDate, setCalendarDate] = useState<Date>(() => new Date())

    const getUser = useQuery<User, Error>({
        queryKey: ["user"],
        queryFn: () => fetchUser(getUserID())
    });

    const getStress = useQuery<Stress[], Error>({
        queryKey: ["stress"],
        queryFn: () => fetchStressList(getUserID())
    });

    const handleEventClick = (eventData: any) => {
        setSelectedEvent(eventData);  // Ensure that the event data is being set here
        setIsEventOpen(true);  // Instead of toggling, explicitly set to true when an event is clicked
    };

    const onEventClose = (updatedStressLevel) => {
        if (!selectedEvent) {
            console.error('No selected event to update');
            return; // Exit the function if selectedEvent is null
        }

        setCalendarEvents(currentEvents =>
            currentEvents.map(event =>
                event.apiID === (selectedEvent as { apiID: string }).apiID ? { ...event, stressLevel: updatedStressLevel } : event
            )
        );

        setIsEventOpen(false);  // Explicitly set to false when closing the logger
        setSelectedEvent(null);  // Also clear the selected event
    };

    function getStressById(id) {
        // Find the object with the matching ID
        const stressData = getStress.data ? getStress.data : []
        if (stressData.length === 0) {
            return null;
        }
        else {
            //const thisEvent = stressData.find(obj => obj.id === id);
            const thisEvent = stressData.find(obj => obj.data.apiID === id);

            // If object is found, return its stress value
            if (thisEvent) {
            } else {
                return null;
            }
            return thisEvent.data.stress;
        }
    }

    const fetchOutlookEvents = async (accessToken) => {
        try {
            const response = await fetch("https://graph.microsoft.com/v1.0/me/calendar/events", {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch Outlook events');
            }

            const data = await response.json();

            // You may want to process and format the data if needed

            return data.value.map((event: any) => {
                let startDateObject: Date = new Date(event.start.dateTime);

                let startTimeString = startDateObject.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' });

                // Parse End time
                let endDateObject: Date = new Date(event.end.dateTime);
                let endTimeString = endDateObject.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' });


                return {
                    "startTime": startTimeString,
                    "endTime": endTimeString,
                    "apiID": event.id,
                    "eventName": event.subject,
                    "description": event.bodyPreview,
                    "location": event.location.displayName,
                    "date": event.start.dateTime,
                    "stressLevel": event.stress || getStressById(event.id),
                };
            })
        } catch (error) {
            console.error('Error fetching Outlook events:', error);
            throw new Error('Failed to fetch Outlook events');
        }
    };

    const syncMicrosoft = async () => {
        try {

            await initializeMSAL();

            const loginRequest = {
                scopes: ['openid', 'profile', 'User.Read', 'Calendars.Read'],
            };

            const authResult = await cca.loginPopup(loginRequest);

            //console.log(loginRequest);
            // Check if access token is present
            if (!authResult.accessToken) {
                console.error('Access token is empty:', authResult);
                throw new Error('Access token is empty');
            }

            // authResult now contains the access token and account info
            console.log('Authentication successful:', authResult);

            // TODO: Fetch Outlook events using the obtained access token
            const fetchedOutlookEvents = await fetchOutlookEvents(authResult.accessToken);

            // Now you can do something with outlookEvents, such as updating state
            setCalendarEvents(fetchedOutlookEvents);

            // Set a flag to indicate that the account is synced / the calendar type is Outlook
            setSyncedAccount(true);
        } catch (error) {
            console.error('Error syncing Microsoft account:', error);
            // Handle error as needed
        }
    };

    const syncGoogle = async () => {
        try {
            // Assuming handleAuthClick is async, await its completion.
            // If handleAuthClick isn't returning a promise, you don't need to await it.
            await googleCalendar.handleAuthClick();

            const startOfDay = new Date();
            startOfDay.setHours(0, 0, 0, 0); // Set to the start of the day

            const endOfDay = new Date();
            endOfDay.setHours(23, 59, 59, 999); // Set to the end of the day


            const result = await googleCalendar.listEvents({
                // timeMin: startOfDay.toISOString(),
                // timeMax: endOfDay.toISOString(),
                showDeleted: true,
                maxResults: 250,
                orderBy: 'updated'
            });

            // Ensure result and result.items are defined before accessing them
            if (result && result.result && result.result.items && result.result.items.length > 0) {

                const eventsDetails = result.result.items.map(item => ({
                    eventId: item.id,
                    startTime: item.start.dateTime || item.start.date, // Use dateTime or fallback to date for all-day events
                    endTime: item.end.dateTime || item.end.date, // Same fallback for end time
                    title: item.summary,
                    eventDescription: item.description,
                    eventLocation: item.location
                }));

                // Log or process the extracted details as needed

                const processedEvents = eventsDetails.map((event: any) => {
                    // Parse Start date / time
                    let startDateObjectGoogle: Date = new Date(event.startTime);
                    let startDateString = (startDateObjectGoogle.getMonth() + 1).toString().padStart(2, '0') + "-" +
                        startDateObjectGoogle.getDate().toString().padStart(2, '0') + "-" +
                        startDateObjectGoogle.getFullYear();
                    let startTimeString = startDateObjectGoogle.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' });

                    // Parse End date / time
                    let endDateObjectGoogle: Date = new Date(event.endTime);
                    let endTimeString: string = endDateObjectGoogle.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' });

                    return {
                        "startTime": startTimeString,
                        "endTime": endTimeString,
                        "apiID": event.eventId,
                        "eventName": event.title,
                        "description": event.eventDescription,
                        "location": event.eventLocation,
                        "stressLevel": event.stress || getStressById(event.eventId),
                        "date": event.startTime
                    }
                });
                setCalendarEvents(processedEvents);

                // If you need to return these details from the function:
                //return eventsDetails;


                // If you want to log the start time of the last event, as in your original request
                setSyncedAccount(true);
            } else {
                // Handle cases where no items are returned or result is not structured as expected
                console.log("No events found or unexpected result structure.");
                // window.confirm("No events found in calendar. Please create events to have them displayed :)");

            }

        } catch (error) {
            // Handle any errors that occurred during the process
            console.error("An error occurred while syncing with Google Calendar:", error);
        }

    };

    const MyDatePicker = () => {
        const CustomInput = ({ value, onClick }) => (
            <button onClick={onClick} className="bubble">
                {value}
            </button>
        );

        const handleStressDateChange = (date: Date) => {
            setCalendarDate(date);
            console.log("CHANGING DATE");
        };

        return (
            <DatePicker
                selected={calendarDate}
                onChange={handleStressDateChange}
                customInput={<CustomInput value={calendarDate} onClick={() => { }} />}
            />
        );
    };

    const Event = (props: any) => {
        return (
            <div className="event">
                <div style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px", textOverflow: "ellipsis" }}>
                        <div className="title">
                            {props.eventName}
                        </div>
                    </div>

                    <div style={{ display: "flex", gap: "6px" }}>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", fontSize: "14px", borderRadius: "12px", gap: "6px" }}>
                            <div className="date">{props.time}</div>
                        </div>
                    </div>
                </div>

                <div style={{ flex: "1" }}>
                    <div style={{ display: "flex", fontSize: '12px', alignSelf: "flex-start", }}>
                        <div className="stress-level" style={{ borderRadius: '10px', padding: '6px 8px', fontWeight: "bold", backgroundColor: "#671bca", border: "1px solid white", }}>
                            {props.stressLevel === null ? "Stress Level: Unavailable" : "Stress Level: " + props.stressLevel}
                        </div>
                    </div>
                </div>

                <div className="info">
                    {props.stressLevel === null ?
                        <div className="bubble" onClick={props.openEvent}>
                            {"LOG STRESS"}
                        </div>
                        :
                        <div className="bubble" onClick={props.openEvent}>
                            {"OPEN"}
                        </div>
                    }
                </div>
            </div >
        );
    };

    function displayEvents(data: any) {
        let eventsToDisplay: any[] = [];

        // DATE CHECKING
        data.map((event: any) => {
            let eventDate = new Date(event.date);
            let calendarDateCopy = calendarDate;
            if (
                eventDate.getFullYear() === calendarDateCopy.getFullYear() &&
                eventDate.getMonth() === calendarDateCopy.getMonth() &&
                eventDate.getDate() === calendarDateCopy.getDate()) {
                eventsToDisplay.push(event);
            }
        });

        //DISPLAY  EVENTS
        if (eventsToDisplay.length !== 0) {
            return eventsToDisplay.map((event: any) => {
                return (
                    <Event
                        key={event.apiID}
                        eventName={event.eventName}
                        description={event.description}
                        time={`${event.startTime} - ${event.endTime}`}
                        date={event.date}
                        stressLevel={event.stressLevel}
                        startTime={event.startTime}
                        openEvent={() => handleEventClick(event)} />
                );
            });
        } else {
            return (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", color: "#55346a", fontSize:"24px", fontWeight: "bold", textAlign: "center", top: "50%", left: "50%", padding: "10vh"
                }}>
                    NO CALENDAR EVENTS FOR THIS DATE
                </div >

            );
        }
    }

    return(
        <CalendarDiv className={`${getUser.data?.data.darkMode ? 'dark-mode' : ''}`}>
            {!syncedAccount ? (
                <SyncButtons>
                    <button onClick={syncMicrosoft}>Sync Microsoft Account</button>
                    <button onClick={syncGoogle}>Sync Google Account</button>
                </SyncButtons>
            ) : (
                isEventOpen ? (
                    <div style={{display:"flex", flexDirection:"column", justifyContent:" center"}}>
                        <StressCalendarLogger selectedEvent={selectedEvent} handleEventClose={onEventClose}/>
                    </div>
                ) : (
                    <>
                        <div className="heading">
                            <div>Upcoming Events</div>
                            <div className="dates">
                                <MyDatePicker/>
                            </div>
                            <div>Welcome, {getUser.data ? getUser.data.data.firstName : ""}!</div>
                        </div>
                        <div className="events-container">
                            {displayEvents(calendarEvents)}
                        </div>
                    </>
                )
            )}
        </CalendarDiv>
    );
}

import {fetcher} from "./Fetcher";
import {mutator} from "./Mutator";
import {Mood} from "../types/Mood";

export function fetchMoodList(uid: string) {
    return fetcher<Mood[]>(`/mood/${uid}`, "GET");
}

export function createMoodEntry(body?: any | null) {
    return mutator("/mood", "POST", body);
}

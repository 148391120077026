import React, {useState} from "react";
import {BedtimeContent, RoutineButton, RoutineMaster, RoutineTimer} from "./BedtimeRoutine.styles";
import {useQuery} from "@tanstack/react-query";
import {Routine} from "../../types/Routine";
import {fetchRoutineList} from "../../services/Routine";
import {CircularProgressbarWithChildren} from 'react-circular-progressbar';
import {getUserID} from "../../services/firebase";
import {User} from "../../types/User";
import {fetchUser} from "../../services/User";

type timerState = "START" | "RUNNING" | "END";

export default function BedtimeRoutine() {
    const [timer, setTimer] = useState(0);
    const [currState, setCurrState] = useState<timerState>("START");
    const [completed, setCompleted] = useState(0);
    const [title, setTitle] = useState("It's Bedtime");
    const intervalRef = React.useRef();

    const getRoutines = useQuery<Routine[], Error>({
        queryKey: ["Routine"],
        queryFn: () => fetchRoutineList(getUserID()),
        refetchInterval: false
    });

    const start = () => {
        setTimer((prev) => {
            if (prev === 0) {
                stop();
                return 0;
            }
            return prev - 1;
        })
    }

    const interval = () => {
        // @ts-ignore
        intervalRef.current = setInterval(() => {
            start();
        }, 1000);
    }

    const stop = () => {
        clearInterval(intervalRef.current);
    }

    const taskCompleted = () => {
        if (currState === "RUNNING") {
            return (
                <p>{completed}/{getRoutines.data?.length} tasks completed!</p>
            );
        } else {
            return (<></>);
        }
    }

    const viewTimer = () => {
        if (currState === "RUNNING") {
            return (
                <p>
                    {timer / 60 > 10 ? <>{(Math.floor(timer / 60)).toFixed(0)}</> : <>0{(Math.floor(timer / 60)).toFixed(0)}</>}
                    :
                    {timer % 60 > 10 ? <>{timer % 60}</> : <>0{timer % 60}</>}
                </p>
            );
        } else {
            return (<></>);
        }
    }

    const buttonClick = () => {
        if (currState === "START") {
            setCurrState("RUNNING");
            setTitle(getRoutines.data![0].data.task_name);
            setTimer(getRoutines.data![0].data.minutes * 60);
            interval();
        } else if (currState === "RUNNING") {
            stop();
            if (completed+1 !== getRoutines.data?.length) {
                setTitle(getRoutines.data![completed+1].data.task_name);
                setTimer(getRoutines.data![completed+1].data.minutes * 60);
                setCompleted(completed+1);
                interval();
            } else {
                setCompleted(completed+1);
                setCurrState("END");
                setTitle("You're done!");
            }
        } else {
            stop();
            setCompleted(0);
            setCurrState("RUNNING");
            setTitle(getRoutines.data![0].data.task_name);
            setTimer(getRoutines.data![0].data.minutes * 60);
            interval();
        }
    }

    const buttonMessage = () => {
        if (getRoutines.data && getRoutines.data.length > 0) {
            if (currState === "START") {
                return(<RoutineButton onClick={() => buttonClick()}>Begin Your Routine</RoutineButton>);
            } else if (currState === "RUNNING") {
                return(<RoutineButton onClick={() => buttonClick()}>Next Item</RoutineButton>);
            } else {
                return(<RoutineButton onClick={() => buttonClick()}>Restart Bedtime Routine</RoutineButton>);
            }
        } else {
            return (<p>You have no bedtime routines! Open the menu on the top right to add routines!</p>);
        }
    }

    const getUser = useQuery<User, Error>({
        queryKey: ["user"],
        queryFn: () => fetchUser(getUserID())
    });

    return (
        <RoutineMaster>
            <div id={"firebaseui-auth-container"} style={{display: "none"}}/>
            <BedtimeContent className={`${getUser.data?.data.darkMode ? 'dark-mode' : ''}`}>
                {taskCompleted()}
                <RoutineTimer>
                    <CircularProgressbarWithChildren value={completed / (getRoutines.data ? getRoutines.data.length : 1) * 100} className={`${getUser.data?.data.darkMode ? 'dark-mode' : ''}`}>
                        <p style={{paddingTop: "40%"}}>{title}</p>
                        {viewTimer()}
                    </CircularProgressbarWithChildren>
                </RoutineTimer>
                {buttonMessage()}
            </BedtimeContent>
        </RoutineMaster>
    );
}

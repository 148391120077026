import React from 'react';
import {
    ButtonArea,
    InfoImage,
    InfoSection,
    LandingTop,
    LearnButton,
    PageButtons,
    ShowButton,
    TitleBlock
} from "./Landing.styles";
import {auth} from "../../services/firebase";
import 'firebaseui/dist/firebaseui.css';

export default function Landing() {
    auth.onAuthStateChanged((user) => {
        if (user) {
            window.location.replace(window.location.href + "dashboard");
        }
    })

    const scroll = (y: number) => {
        window.scrollBy(0, y - window.scrollY);
    }

    return (
        <>
            <LandingTop>
                <div id={'firebaseui-auth-container'}/>
                <div className="whiteText" id="loader">Loading...</div>
                <TitleBlock>
                    <h1>Z<sup>3</sup>-Wellness</h1>
                    <h2>Track your sleep and bring sleep back under your control!</h2>
                    <LearnButton onClick={() => scroll(1000)}>Learn What We Are All About</LearnButton>
                </TitleBlock>
            </LandingTop>

            <PageButtons>
                <ButtonArea>
                    <a href={"#routine"}>
                        <ShowButton imageurl={"images/bedtime_icon_white.png"} style={{backgroundColor: "#1f9ce4"}}></ShowButton>
                        <h2 className="whiteText">Routines</h2>
                    </a>
                </ButtonArea>
                <ButtonArea>
                    <a href={"#exercise"}>
                        <ShowButton imageurl={"images/log_icon_white.png"} style={{backgroundColor:"#625ad8"}}/>
                        <h2 className="whiteText">Activity Log</h2>
                    </a>
                </ButtonArea>
                <ButtonArea>
                    <a href={"#personality"}>
                        <ShowButton imageurl={"images/mind_icon_white.png"} style={{backgroundColor:"#7339ab"}}/>
                        <h2 className="whiteText">Mindfulness and Personality</h2>
                    </a>
                </ButtonArea>
            </PageButtons>

            <InfoSection id={"routine"} imageurl={"images/calendar.jpg"} gradcolor={"#1f9ce4"} leftright={"circle at top left"}>
                <h1>Calendar and Routines</h1>
                <InfoImage imageurl={"images/bedtime_icon_white.png"}/>
                <h3>
                    Keep track of your nightly routine steps  with our bedtime
                    routine tracker. Set your nightly tasks and Z<sup>3</sup>-Wellness
                    will time you to make sure your routine stays consistent
                    so you set yourself up for a good night’s sleep.
                    <br/><br/>
                    Sync Z<sup>3</sup>-Wellness with your Google Calendar and view all the
                    events you have coming up and rate them on stress level.
                    Z<sup>3</sup>-Wellness will allow you to keep track of  your stress level and how it is affecting your sleep.
                </h3>
            </InfoSection>

            <InfoSection id={"exercise"} imageurl={"images/gym.jpg"} gradcolor={"#625ad8"} leftright={"circle at top right"} style={{textAlign:"right"}}>
                <h1>Exercise and Activity Trackers</h1>
                <InfoImage imageurl={"images/log_icon_white.png"} style={{display: "block", alignSelf:"right", marginLeft: "auto"}}/>
                <h3 style={{marginLeft: "auto", marginRight: "50px"}}>
                    Did you know that exercise has an effect on your quality of sleep?
                    Keeping track of your exercise will allow you to be more in control of your sleep health.<br/><br/>
                    Z<sup>3</sup>-Wellness allows you to sync with your fitness tracker to track both exercise and sleep!
                    If you do not own a fitness tracker, do not fret! Z<sup>3</sup>-Wellness provides options to self
                    log your sleep hours and exercise amount.
                </h3>
            </InfoSection>

            <InfoSection id={"personality"} imageurl={"images/meditation.jpg"} gradcolor={"#7339ab"} leftright={"circle at top left"}>
                <h1>Mindfulness and Personality</h1>
                <InfoImage imageurl={"images/mind_icon_white.png"}/>
                <h3>
                    Are you more of a morning or evening person? How do other traits of your personality
                    line up with your sleep habits? Take our personality tests and see how your personality
                    affects your sleep health.
                    <br/><br/>
                    Take steps to improve your sleep. We offer in depth mindfulness activities to help calm
                    your body and mind before bed!
                    <br/><br/>
                </h3>
            </InfoSection>
        </>
    );
}

import React from 'react';

export default function MindfulnessOverview() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <br />
                    <iframe
                        width="550"
                        style={{ maxWidth: '100%', aspectRatio: '16 / 9' }}
                        src="https://www.youtube.com/embed/7FccK9UjuF0"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>
                    <br /><br/>

                    <p>
                            <b>Video Description:</b> This video with Dr. Amishi Jha provides a quick overview of mindfulness. In this video, you can learn about some of the answers on training and building resilience.
                    </p>
                    <p>
                            <a href="https://www.nccih.nih.gov/health/meditation-and-mindfulness-what-you-need-to-know">
                                    Source
                            </a>
                    </p>
                    <br />
                    <hr className="hr-settings"/>
                    <br />
                    <p>Spotify Meditation Playlist</p>
                    <br />
                    <a href="https://open.spotify.com/playlist/2ozb9cgwMcl2SDWK4SLRp8">
                            <img
                                src="https://media.gettyimages.com/id/1267497795/photo/young-woman-in-spiritual-pose-holding-the-light.jpg?s=612x612&w=0&k=20&c=7HiquJMEZQV22fvhm0PIm61GIzdjcGNEUGDmQ9W0ekY="
                                alt="Spotify Meditation Playlist"
                                width="550"
                                height="400"
                                style={{ maxWidth: '100%', height: 'auto' }}
                            />
                    </a>
            </div>
        );
}
